import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, FormLabel, Heading, HStack, Input, VStack} from "@chakra-ui/react";
import DayCard from "./DayCard";
import FindBooking from "./FindBooking";


export default function Bookings() {

    const [days, setDays] = useState([]);

    function getDays() {
        // Get today's date
        const today = new Date();
        // Calculate the difference between today and the start of the week (Monday)
        const diff = today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1);
        // Set the date to the start of the week (Monday)
        const monday = new Date(today.setDate(diff));

        // Array to store the dates from Monday to Saturday
        const weekDates = [];

        // Days of the week
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        // Iterate from Monday to Saturday and push the dates to the array
        for (let i = 0; i < 6; i++) {
            const date = new Date(monday);
            date.setDate(monday.getDate() + i);
            // Format the date as 'MM/DD/YYYY'
            const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            weekDates.push({day: daysOfWeek[i], date: formattedDate});
        }
        console.log(weekDates)
        setDays(weekDates)
    }

    useEffect(() => {
        getDays()
    }, []);

    return (
        <Box w={'100%'} h={'fit-content'} mb={'auto'}>
            <Heading fontWeight={'normal'} fontSize={'5xl'}>This Weeks Bookings</Heading>
            <HStack w={'95%'} m={'auto'} justifyContent={'space-between'} mt={25} overflowY={'auto'}>
                {days.map(d => <DayCard day={d.day} date={d.date}/>)}
            </HStack>
            <FindBooking/>
        </Box>
    )

}