import React, {useEffect, useState} from 'react';
import {
    Button, Center,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormLabel,
    Input, Spinner,
    useToast,
    VStack
} from "@chakra-ui/react";
import {formatPhoneNumber} from "../../../utils/helpers";
import axios from "axios";


export default function EditAdminDrawer({admin, isOpen, onClose, getAdmins}) {

    const toast = useToast();

    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [loading, setLoading] = useState(false);

    const handleFirstNameChange = (e) => setFirstName(e.target.value)
    const handleLastNameChange = (e) => setLastName(e.target.value)
    const handleEmailChange = (e) => setEmail(e.target.value)
    const handlePhoneChange = (e) => setPhone(formatPhoneNumber(e.target.value))

    const saveChanges = async () => {
        const data = {
            firstName: firstName === admin.firstName ? null : firstName,
            lastName: lastName === admin.lastName ? null : lastName,
            email: email === admin.email ? null : email,
            phone: phone === admin.phone ? null : phone,
        }
        const response = await axios.patch(`/api/admins/updateAdmin/${admin._id}`, data);
        if (response.status === 200) {
            toast({
                title: 'Admin Updated',
                description: `Successfully Updated ${admin.firstName} ${admin.lastName}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            getAdmins()
            onClose()
        }
    }

    const resetPassword = async () => {
        setLoading(true)
        const response = await axios.patch(`/api/admins/resetPassword/${admin._id}`);
        if (response.status === 200) {
            toast({
                title: 'Password Reset',
                description: `Sent Password Reset email to: ${admin.firstName} ${admin.lastName}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        if (admin) {
            setFirstName(admin.firstName)
            setLastName(admin.lastName)
            setEmail(admin.email)
            setPhone(admin.phone)
        }
    }, [admin]);

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
        >
            <DrawerOverlay/>
            <DrawerContent>
                <DrawerCloseButton/>
                <DrawerHeader>Edit Admin</DrawerHeader>

                <DrawerBody>
                    {loading && ( // Conditionally render the spinner when loading is true
                        <Center
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            zIndex={10}
                            background="rgba(255, 255, 255, 0.5)" // Adjust the background color and opacity
                        >
                            <Spinner />
                        </Center>
                    )}
                    <VStack gap={5}>
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input placeholder={'First Name'} value={firstName} onChange={handleFirstNameChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Input placeholder={'Last Name'} value={lastName} onChange={handleLastNameChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input placeholder={'Email'} value={email} onChange={handleEmailChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone</FormLabel>
                            <Input placeholder={'Phone Number'} value={phone} onChange={handlePhoneChange}/>
                        </FormControl>
                        <Button w={'100%'} colorScheme={'black'} variant={'outline'} onClick={resetPassword}>Reset
                            Password</Button>
                        <Button w={'100%'} colorScheme={'blackAlpha'} bgColor={'black'} onClick={saveChanges}>Save
                            Changes</Button>
                    </VStack>
                </DrawerBody>
                <DrawerFooter>

                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}