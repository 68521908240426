import React, {useEffect, useState} from "react";
import {
    Box, Heading, HStack,
    Stack,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle,
    Text,
    useSteps
} from "@chakra-ui/react";
import Calandar from "./Calandar";
import TimeSlots from "./TimeSlots";
import ContactInformation from "./ContactInformation/ContactInformation";
import Services from "./Services";
import Payment from "./Payment";
import Packages from "./Packages/Packages";
import StepperButtons from "../../components/StepperButtons";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const steps = [
    {title: 'First', description: 'Service'},
    {title: 'Second', description: 'Select Package'},
    {title: 'Third', description: 'Date & Time'},
    {title: 'Fourth', description: 'Contact Information'},
    {title: 'Fifth', description: 'Payment'},
]

const Scheduling = () => {
    const {bookingService, bookingPackage} = useSelector(state => state);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [selectedService, setSelectedService] = useState();
    const [selectedPackage, setSelectedPackage] = useState();
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedDate, setSelectedDate] = useState(true);
    const [contactInformation, setContactInformation] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    })
    const {activeStep, setActiveStep} = useSteps({
        index: 1,
        count: steps.length,
    })

    useEffect(() => {
        if (selectedDay !== null && selectedTime !== null) {
            setSelectedDate(false)
        }
        console.log(selectedTime)
    }, [selectedTime, selectedDay]);

    useEffect(() => {
        if (bookingService != null) {
            setSelectedService(bookingService);
        }
        if (bookingPackage != null) {
            setSelectedPackage(bookingPackage);
        }
        const serviceName = queryParams.get("service");
        const packageName = queryParams.get("package");
        console.log(serviceName, packageName)
        if (serviceName && packageName) {
            setActiveStep(3)
        }
    }, []);


    return (
        // <Box w={{base: '75%', sm: '90%', md: '85%', lg: '75%', xl: '65%'}} pt={25} m={'auto'} h={'100%'} pb={10}>
        <Box w={{base: '75%', sm: '90%', md: '85%', lg: '75%', xl: '65%'}} pt={25} m={'auto'} h={'100vh'} pb={10}>
            <Text fontSize={'5xl'} mb={15}>Schedule Your Appointment Today!</Text>
            {/*<Box w={'100%'} overflowY={'auto'}>*/}
            {/*    <Stepper index={activeStep} mt={25} mb={25}>*/}
            {/*        {steps.map((step, index) => (*/}
            {/*          <Step key={index} mL={5} mr={5}>*/}
            {/*              <StepIndicator>*/}
            {/*                  <StepStatus*/}
            {/*                    complete={<StepIcon/>}*/}
            {/*                    incomplete={<StepNumber/>}*/}
            {/*                    active={<StepNumber/>}*/}
            {/*                  />*/}
            {/*              </StepIndicator>*/}
            {/*              <Box flexShrink='0'>*/}
            {/*                  <StepTitle>{step.title}</StepTitle>*/}
            {/*                  <StepDescription>{step.description}</StepDescription>*/}
            {/*              </Box>*/}
            {/*              <StepSeparator/>*/}
            {/*          </Step>*/}
            {/*        ))}*/}
            {/*    </Stepper>*/}
            {/*</Box>*/}
            {/*{activeStep === 1 ?*/}
            {/*  <Services selectedService={selectedService} setSelectedService={setSelectedService}*/}
            {/*            activeStep={activeStep} setActiveStep={setActiveStep}/>*/}
            {/*  : <></>}*/}
            {/*{activeStep === 2 ? <Packages setSelectedPackage={setSelectedPackage} service={selectedService}*/}
            {/*                              setActiveStep={setActiveStep} activeStep={activeStep}/> : <></>}*/}
            {/*{activeStep === 3 ?*/}
            {/*  <Box m={5} mb={10} h={'700px'} pb={50} mb={'10%'}>*/}
            {/*      <Box width={'90%'} m={'auto'} mb={15}>*/}
            {/*          <Text align={'left'} fontWeight={'bold'} fontSize={'xl'}>Select Date</Text>*/}
            {/*      </Box>*/}
            {/*      <HStack width={'100%'} m={'auto'} justifyContent={'space-evenly'}*/}
            {/*              alignItems={'center'} h={'fit-content'} wrap={'wrap'}>*/}
            {/*          <Calandar selectedDay={selectedDay} setSelectedDay={setSelectedDay}*/}
            {/*                    selectedService={selectedService}/>*/}
            {/*          <TimeSlots setSelectedTime={setSelectedTime} selectedTime={selectedTime}*/}
            {/*                     selectedService={selectedService} selectedDay={selectedDay}/>*/}
            {/*      </HStack>*/}
            {/*      <StepperButtons activeStep={activeStep} setActiveStep={setActiveStep} backDisabled={false}*/}
            {/*                      nextDisabled={selectedDate}/>*/}
            {/*  </Box>*/}
            {/*  : <></>}*/}
            {/*{activeStep === 4 ?*/}
            {/*  <Box h={'700px'}>*/}
            {/*      <ContactInformation contactInformation={contactInformation}*/}
            {/*                          setContactInformation={setContactInformation} activeStep={activeStep}*/}
            {/*                          setActiveStep={setActiveStep}/>*/}
            {/*  </Box>*/}
            {/*  : <></>}*/}
            {/*{activeStep === 5 ?*/}
            {/*  <>*/}
            {/*      <Payment selectedService={selectedService} selectedPackage={selectedPackage}*/}
            {/*               contactInformation={contactInformation}*/}
            {/*               selectedDay={selectedDay} selectedTime={selectedTime}/>*/}
            {/*  </>*/}
            {/*  : <></>}*/}
            {/*<Stack direction={'row'} mt={25} justifyContent={'space-between'} spacing={3}>*/}
            {/*</Stack>*/}
            <Heading>Coming Soon!</Heading>

        </Box>
    )
}

export default Scheduling;
