import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Heading,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure
} from "@chakra-ui/react";
import API from "../../../utils/api";
import AddAdminModal from "./AddAdminModal";
import {BsPencilFill, BsXCircleFill} from "react-icons/bs";
import RemoveAdminModal from "./RemoveAdminModal";
import EditAdminDrawer from "./EditAdminDrawer";


export default function AdminTable() {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const btnRef = React.useRef()

    const [admins, setAdmins] = useState([])
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [showAddAdmin, setShowAddAdmin] = useState(false);
    const [showRemoveAdmin, setShowRemoveAdmin] = useState(false);
    const [showEditAdmin, setShowEditAdmin] = useState(false);

    const showRemoveAdminModal = () => setShowRemoveAdmin(true);
    const closeRemoveAdminModal = () => setShowRemoveAdmin(false);
    const showAddAdminModal = () => setShowAddAdmin(true);
    const closeAddAdminModal = () => setShowAddAdmin(false);
    const showEditAdminDrawer = () => setShowEditAdmin(true);
    const closeEditAdminDrawer = () => setShowEditAdmin(false);

    const getAdmins = async () => {
        const tmpAdmins = await API.getAdmins();
        setAdmins(tmpAdmins);
    }

    const renderAdmin = (admin) => {
        const handleRemoveClick = () => {
            setSelectedAdmin(admin);
            showRemoveAdminModal()
        }
        const handleEditClick = () => {
            setSelectedAdmin(admin);
            showEditAdminDrawer()
        }
        return (
            <Tr>
                <Td>{admin.firstName}</Td>
                <Td>{admin.lastName}</Td>
                <Td>{admin.email}</Td>
                <Td>{admin.phone}</Td>
                <Td>
                    <IconButton
                        onClick={handleRemoveClick}
                        borderRadius={25}
                        bgColor={'transparent'}
                        colorScheme='gray'
                        aria-label='remove'
                        icon={<BsXCircleFill color={'red'} size={20}/>}
                    />
                    <IconButton
                        onClick={handleEditClick}
                        borderRadius={25}
                        bgColor={'transparent'}
                        colorScheme='gray'
                        aria-label='remove'
                        icon={<BsPencilFill color={'#417dff'} size={20}/>}
                    />
                </Td>
            </Tr>
        )
    }

    useEffect(() => {
        getAdmins();
    }, []);

    return (
        <Box w={'75%'} m={'auto'} h={'100vh'}>
            <Heading fontWeight={'normal'} fontSize={'5xl'}>Admins</Heading>
            <TableContainer bgColor={'#FFFFFF'} borderRadius={5} mt={25}>
                <Table variant='striped' colorScheme={'blackAlpha'}>
                    <Thead>
                        <Tr>
                            <Th>First Name</Th>
                            <Th>Last Name</Th>
                            <Th>Email</Th>
                            <Th>Phone</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {admins.map(a => renderAdmin(a))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Button mt={25} colorScheme={'blackAlpha'} bgColor={'black'} color={'white'} fontWeight={'normal'}
                    onClick={showAddAdminModal}>
                Add Admin
            </Button>
            <AddAdminModal showAddAdmin={showAddAdmin} closeAddAdminModal={closeAddAdminModal} getAdmins={getAdmins}/>
            <RemoveAdminModal getAdmins={getAdmins} closeRemoveAdminModal={closeRemoveAdminModal}
                              showRemoveAdmin={showRemoveAdmin} admin={selectedAdmin}/>
            <EditAdminDrawer getAdmins={getAdmins} admin={selectedAdmin} onClose={closeEditAdminDrawer}
                             isOpen={showEditAdmin}/>
        </Box>
    )
}