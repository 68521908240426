export const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from the phone number
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Format the phone number
    let formattedNumber = '';
    if (cleaned.length >= 1) {
        formattedNumber = '(' + cleaned.slice(0, 3);
    }
    if (cleaned.length > 3) {
        formattedNumber += ') - ' + cleaned.slice(3, 6);
    }
    if (cleaned.length > 6) {
        formattedNumber += ' - ' + cleaned.slice(6, 10);
    }

    return formattedNumber;
}

export const formatTime = (time24) => {
    let hour12 = parseInt(time24, 10);
    const suffix = hour12 >= 12 ? 'pm' : 'am';
    hour12 = hour12 % 12 || 12; // Convert hour to 12-hour format

    return `${hour12}${suffix}`;
};
