import React from "react";
import {Box, Heading, Stack, Text} from "@chakra-ui/react";
import WhiteButton from "../../../components/Buttons/WhiteButton";
import heroImg from '../../../assets/hero.jpeg'
import {useNavigate} from "react-router-dom";

const Hero = () => {

    const navigate = useNavigate();

    return (
        <Box
            style={{
                backgroundImage: `url(${heroImg})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
            h={[500, 1000]}
            alignItems={'center'}
            position={'relative'}
            w={"100%"}
        >
            <Stack w={["90%", "37%"]} bgColor={'rgba(0,0,0,.45)'}
                   justifyContent="center" alignItems="left" m={'auto'} mb={20}
                   borderRadius={10} p={[5, 10]} color={'white'} position={'absolute'} top={[null, null, null, 250]}
                   left={[5, null, null, 250]} mt={[150, 0]}>
                <Box align={'left'}>
                    <Heading fontSize={["5xl",'7xl']} fontWeight={'normal'} w={["80%",'fit-content']}>Love The Way You</Heading>
                    <Heading fontSize={["5xl",'7xl']} fontWeight={'normal'} w={["80%",'fit-content']}>Look And Feel</Heading>
                    <Text fontSize={["xl",'2xl']} fontWeight={'semiBold'}>Transform your body effortlessly with non-invasive,
                        contouring innovations for beauty.</Text>
                </Box>
                <WhiteButton onClick={() => navigate('/scheduling')}>Book Now</WhiteButton>
            </Stack>
        </Box>
    )
}

export default Hero;