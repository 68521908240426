import React from "react";
import {Box, Button, Stack, Text} from "@chakra-ui/react";
import Hero from "./Hero/index.js";
import Mission from "./Mission/index.js";
import Subscribe from "./Subscribe/index.js";
import AmazingResults from "./AmazingResults/index.js";
import ScheduleNow from "./ScheduleNow/index.js";
import Footer from "../../components/Footer";
import Services from "./Services";
import ContactUs from "./ContactUs";

const Landing = () => {

    return (
        <Box mt={-100} w={'100%'}>
            <Hero/>
            <AmazingResults/>
            <Services/>
            <Subscribe/>
            <ContactUs/>
            {/*<Mission/>*/}
            {/*<ScheduleNow/>*/}
        </Box>
    )
}

export default Landing