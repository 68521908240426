import React, {useState} from 'react';
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    HStack,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import axios from "axios";
import SmallPackageCard from "./SmallPackageCard";
import EditServiceModal from "./EditServiceModal";
import NewPackageModal from "./NewPackageModal";


export default function ServiceAccordion({service, getServices}) {

    const [showAddPackage, setShowAddPackage] = useState(false);
    const [showEditService, setShowEditService] = useState(false);

    const closeAddPackageModal = () => setShowAddPackage(false);
    const showAddPackageModal = () => setShowAddPackage(true);
    const closeEditServiceModal = () => setShowEditService(false);
    const showEditServiceModal = () => setShowEditService(true);

    async function removeService() {
        const removedService = await axios.delete(`/api/service/${service._id}`);
        getServices();
    }

    function renderServicePackages() {
        if (service.packages.length > 0) {
            return service.packages.map(pkg => <SmallPackageCard serviceId={service._id} getServices={getServices}
                                                                 sPackage={pkg}/>)
        } else {
            return <Text fontSize={'2xl'}>No Packages Created.</Text>
        }
    }

    return (
        <AccordionItem>
            <h2>
                <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                        <Text fontSize={'3xl'}>
                            {service.name}
                        </Text>
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} align={'left'} bgColor={'white'} borderRadius={10}>
                <VStack align={'left'}>
                    <Text fontSize={'3xl'} fontWeight={'bold'}>Description</Text>
                    <Text fontSize={'xl'}>{service.description}</Text>
                    <Text fontSize={'3xl'} fontWeight={'bold'}>Brief Description</Text>
                    <Text fontSize={'xl'}>{service.brief}</Text>
                    <Text fontSize={'3xl'} fontWeight={'bold'}>Packages</Text>
                    <HStack>
                        {renderServicePackages()}
                    </HStack>

                </VStack>
                <HStack w={'100%'} justifyContent={'space-between'} h={'fit-content'} alignItems={'center'} mt={10}>
                    <VStack w={200}>
                        <Button w={'100%'} onClick={showAddPackageModal} colorScheme={'blue'} variant={'outline'}>
                            Add Package
                        </Button>
                        <Button w={'100%'} onClick={showEditServiceModal} colorScheme={'blackAlpha'}
                                bgColor={'black'} color={'white'}>
                            Edit Service
                        </Button>
                    </VStack>
                    <Button onClick={removeService} colorScheme={'red'}>Remove Service</Button>
                </HStack>
            </AccordionPanel>
            <NewPackageModal service={service} getServices={getServices} closeAddPackageModal={closeAddPackageModal}
                             showAddPackage={showAddPackage}/>
            <EditServiceModal getServices={getServices} closeEditServiceModal={closeEditServiceModal} service={service}
                              showEditService={showEditService}/>
        </AccordionItem>
    )
}