import React, {useState} from 'react';
import {Button, Card, Text, VStack} from "@chakra-ui/react";
import EditPackageModal from "./EditPackageModal";


export default function SmallPackageCard({getServices, sPackage, serviceId}) {

    const [showEditPackage, setShowEditPackage] = useState(false);

    const closeEditPackageModal = () => setShowEditPackage(false);
    const showEditPackageModal = () => setShowEditPackage(true);


    return (
        <Card pt={2} w={175}>
            <VStack justifyContent={'center'} alignItems={'center'} pl={2} pr={2}>
                {/*<Text fontSize={'2xl'}>{servicePackage.name}</Text>*/}
                {/*<Text fontSize={'2xl'}>${servicePackage.price}</Text>*/}
                <Text fontWeight={'bold'} align={'center'} fontSize={'2xl'}>{sPackage.name}</Text>
                <Text fontSize={'2xl'}>${sPackage.price}</Text>
                <Text align={'center'} fontSize={'lg'}>{sPackage.description}</Text>
            </VStack>
            <Button colorScheme={'blackAlpha'} bgColor={'black'} color={'white'} mt={2} size={'sm'} pl={2} pr={2}
                    width={'100%'} onClick={showEditPackageModal}>Edit Package</Button>
            <EditPackageModal closeEditPackageModal={closeEditPackageModal} showEditPackage={showEditPackage}
                              getServices={getServices} serviceId={serviceId} sPackage={sPackage}/>
        </Card>
    )
}