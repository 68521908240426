import React, {useState} from 'react';
import {Button, FormControl, FormLabel, Heading, Input, useToast, VStack} from "@chakra-ui/react";
import {formatPhoneNumber} from "../../../utils/helpers";
import axios from "axios";


export default function EditCustomerForm({customer, getCustomers}) {
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState(customer.firstName)
    const [lastName, setLastName] = useState(customer.lastName)
    const [email, setEmail] = useState(customer.email)
    const [phone, setPhone] = useState(customer.phone)

    const handleFirstNameChange = (e) => setFirstName(e.target.value)
    const handleLastNameChange = (e) => setLastName(e.target.value)
    const handleEmailChange = (e) => setEmail(e.target.value)
    const handlePhoneChange = (e) => setPhone(formatPhoneNumber(e.target.value))

    const saveCustomer = async () => {
        const data = {}
        if(firstName !== customer.firstName) data.firstName = firstName
        if(lastName !== customer.lastName) data.lastName = lastName
        if(email !== customer.email) data.email = email
        if(phone !== customer.phone) data.phone = phone

        const response = await axios.put(`/api/customer/editCustomer/${customer._id}`, data)
        if (response.status === 200){
            toast({
                title: 'Customer Updated',
                status: 'info',
                duration: 3000,
                isClosable: true,
            })
            getCustomers()
        }
    }

    return (
        <VStack w={'20%'}>
            <Heading fontSize={'2xl'}>Edit Customer</Heading>
            <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input bgColor={'white'} value={firstName} onChange={handleFirstNameChange}
                       placeholder={"First Name"}/>
            </FormControl>
            <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input bgColor={'white'} value={lastName} onChange={handleLastNameChange}
                       placeholder={"Last Name"}/>
            </FormControl>
            <FormControl>
                <FormLabel>Email</FormLabel>
                <Input bgColor={'white'} value={email} onChange={handleEmailChange} placeholder={"Email"}/>
            </FormControl>
            <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input bgColor={'white'} value={phone} onChange={handlePhoneChange} placeholder={"Phone Number"}/>
            </FormControl>
            <Button colorScheme={'green'} onClick={saveCustomer}>Save Customer</Button>
        </VStack>
    )
}