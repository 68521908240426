import axios from "axios";

const API = {
    getServices: async () => {
        const services = await axios.get("/api/service/");
        return services.data;
    },
    getAdmins: async () => {
        const response = await axios.get("/api/admins");
        return response.data;
    },
    findBookings: async (data) => {
        const {firstName, lastName, email, phone} = data;
        const queryString = `${firstName ? `firstName=${firstName}` : ''}${lastName ? `${firstName ? '&' : ''}lastName=${lastName}` : ''}${email ? `&email=${email}` : ''}${phone ? `&phone=${phone}` : ''}`;
        const response = await axios.get(`/api/bookings/find?${queryString}`)
        return response.data;
    },
    findCustomers: async (data) => {
        const {firstName, lastName, email, phone} = data;
        const queryString = `${firstName ? `firstName=${firstName}` : ''}${lastName ? `${firstName ? '&' : ''}lastName=${lastName}` : ''}${email ? `&email=${email}` : ''}${phone ? `&phone=${phone}` : ''}`;
        const response = await axios.get(`/api/customer/find?${queryString}`)
        return response.data;
    },
    cancelBooking: async (bookingId) => {
        const response = await axios.delete(`/api/bookings/${bookingId}`);
    }
}

export default API