import React, {useEffect, useState} from "react";
import {IconButton, Text} from "@chakra-ui/react";

const Day = ({selectedDay, setSelectedDay, dom, date, disabled, currentDay, currentMonth}) => {
    const actualDate = new Date();

    const isDisabled = () => {
        let disabled = false;
        if(dom.day < actualDate.getDate()){
            disabled = true;
        }
        if(actualDate.getMonth() + 1 < currentMonth){
            disabled = false;
        }
        if (actualDate.getMonth() + 1 > currentMonth){
            disabled = true
        }
        return disabled;
    }

    useEffect(() => {
        if(date === "2/23/2024"){
            console.log("date",date)
            console.log("selectedDay",selectedDay)
            console.log(selectedDay === date)
        }
    }, []);

    return (
        <IconButton
            isRound={true}
            variant='solid'
            colorScheme={selectedDay === date ? 'green' : 'blackAlpha'}
            bgColor={selectedDay === date ? "" : "black"}
            onClick={e => {
                setSelectedDay(date)
                console.log("Setting selected day: ", date)
            }}
            aria-label='Done'
            fontSize='20px'
            icon={<Text>{dom.day}</Text>}
            isDisabled={disabled || isDisabled()}
        />
    )
}

export default Day;