import React, {useState} from 'react'
import {Button, FormControl, FormLabel, Heading, Input, VStack} from "@chakra-ui/react";
import {formatPhoneNumber} from "../utils/helpers";


export default function CustomerSearchForm({heading, handleSearch, customer, setCustomer}) {



    const handleFirstNameChange = (e) => setCustomer({...customer, firstName: e.target.value})
    const handleLastNameChange = (e) => setCustomer({...customer, lastName: e.target.value})
    const handleEmailChange = (e) => setCustomer({...customer, email: e.target.value})
    const handlePhoneChange = (e) => setCustomer({...customer, phone: formatPhoneNumber(e.target.value)})


    const checkValues = () => {
        return !customer.firstName && !customer.lastName && !customer.email && !customer.phone;
    }

    return (
        <VStack mb={'auto'} mt={4} mr={'auto'}>
            <Heading>{heading}</Heading>
            <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input bgColor={'white'} value={customer.firstName} onChange={handleFirstNameChange}
                       placeholder={"First Name"}/>
            </FormControl>
            <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input bgColor={'white'} value={customer.lastName} onChange={handleLastNameChange}
                       placeholder={"Last Name"}/>
            </FormControl>
            <FormControl>
                <FormLabel>Email</FormLabel>
                <Input bgColor={'white'} value={customer.email} onChange={handleEmailChange} placeholder={"Email"}/>
            </FormControl>
            <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input bgColor={'white'} value={customer.phone} onChange={handlePhoneChange} placeholder={"Phone Number"}/>
            </FormControl>
            <Button onClick={handleSearch} isDisabled={checkValues()} colorScheme={'green'}>Search</Button>
        </VStack>
    )
}