import React from "react";
import {Box, Heading, HStack, Img, Text, VStack} from "@chakra-ui/react";
import office from '../../assets/office.png'
import GoogleMapReact from 'google-map-react';
import missionIcon from '../../assets/mission-icon.svg';
import goalIcon from '../../assets/goal-icon.svg';

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export default function AboutUs() {

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        <Box pt={100}>
            <HStack w={{base: '48%', md: '75%', lg: '70%', xl: '60%'}} m={'auto'} h={500} justifyContent={'center'} spacing={200} >
                <VStack w={'48%'} h={'100%'} justifyContent={'space-evenly'}>
                    <Img src={missionIcon}/>
                    <Heading fontWeight={'normal'} fontSize={'7xl'}>Our Mission</Heading>
                    <Text fontSize={'lg'}  h={500}>
                        Our mission is to help my clients achieve their desired body shape and confidence through safe,
                        effective, and non-invasive body contouring treatments. We are committed to providing personalized care
                        and attention to each client, using the latest technology and techniques to deliver optimal results. My
                        goal is to create a comfortable and welcoming environment where clients can feel relaxed and confident
                        in their journey towards a slimmer and more toned body. I believe that everyone deserves to feel great
                        about their appearance, and we are dedicated to helping our clients achieve their body goals in a
                        healthy and sustainable way.
                    </Text>
                </VStack>
                <VStack w={'48%'} h={'100%'} justifyContent={'space-evenly'}>
                    <Img src={goalIcon} mb={-35} mt={-53}/>
                    <Heading fontWeight={'normal'} fontSize={'7xl'}>Our Goal</Heading>
                    <Text fontSize={'lg'} h={500}>
                        Our goal is to create a comfortable and welcoming environment where clients can feel relaxed and confident
                        in their journey towards a slimmer and more toned body. I believe that everyone deserves to feel great
                        about their appearance, and we are dedicated to helping our clients achieve their body goals in a
                        healthy and sustainable way.
                    </Text>
                </VStack>
            </HStack>
            <HStack mt={100} bgColor={'teal.500'} p={10} color={'white'}>
                <Img src={office} h={600}/>
                <VStack w={'90%'} fontSize={'2xl'}>
                    <Heading fontWeight={'normal'} fontSize={'6xl'}>Visit Us!</Heading>
                    <Box mt={75} mb={25}>
                        <Text align={'left'}>Salon Lofts</Text>
                        <Text>23541 Westheimer Pkwy, Katy, TX 77494</Text>
                    </Box>
                    <VStack w={'50%'}>
                        {days.map(d => <HStack justifyContent={'space-between'} w={'80%'}>
                            <Text align={'left'}>{d}</Text>
                            <Text>9am - 5pm</Text>
                        </HStack>)}
                    </VStack>
                </VStack>
            </HStack>
            {/*<Box h={200}>*/}
            {/*    <GoogleMapReact*/}
            {/*        bootstrapURLKeys={{ key: "" }}*/}
            {/*        defaultCenter={defaultProps.center}*/}
            {/*        defaultZoom={defaultProps.zoom}*/}
            {/*    >*/}
            {/*        <AnyReactComponent*/}
            {/*            lat={59.955413}*/}
            {/*            lng={30.337844}*/}
            {/*            text="My Marker"*/}
            {/*        />*/}
            {/*    </GoogleMapReact>*/}
            {/*</Box>*/}
        </Box>
    )
}

const AnyReactComponent = ({ text }) => <div>{text}</div>;
