import React, {useState} from 'react'
import {Alert, AlertDescription, AlertIcon, Box, Card, Heading, Img, Input, VStack} from "@chakra-ui/react";
import logo from '../../assets/logo-black.png'
import BlackButton from "../../components/Buttons/BlackButton";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {SET_USER} from "../../utils/actions";

export default function AdminLogin() {

    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    const [showError, setShowError] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleLogin = async () => {
        try {
            let response = await axios.post('/api/user/login', user);
            console.log(response.status);
            const loggedInUser = response.data;
            if(showError){
                setShowError(false)
            }
            if(!loggedInUser.isAdmin){
                setShowError(true)
            } else{
                dispatch({
                    type: SET_USER,
                    user: loggedInUser
                })
                navigate('/admin-dashboard')
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setShowError(true)
                console.log('Error message:', error.response.data.message);
            }
        }
    }

    return (
        <Box mt={75} pb={250}>
            <Heading fontWeight={'normal'}>Admin Login</Heading>
            <Card w={500} h={'fit-content'} m={'auto'} p={5}>
                <VStack>
                    <Img src={logo} w={450} m={'auto'} mb={5}/>
                    <Input placeholder={'Email'} type={'email'} value={user.email} onChange={e => setUser({...user, email: e.target.value})}/>
                    <Input placeholder={'Password'} type={'password'} value={user.password} onChange={e => setUser({...user, password: e.target.value})}/>
                    <BlackButton onClick={handleLogin}>Login</BlackButton>
                    {showError ? <Alert status={'error'} >
                        <AlertIcon/>
                        <AlertDescription>Invalid login credentials.</AlertDescription>
                    </Alert> : <></>}

                </VStack>
            </Card>
        </Box>

    )
}
