import React from 'react';
import {Button} from "@chakra-ui/react";


export default function WhiteButton({children, w, onClick, border, borderRadius, outlined, color}){

    const style = {
        width: w ? w : 'fit-content',
        border: border ? border : "",
        borderRadius: borderRadius ? borderRadius : "",
        color: color ? color : "black"
    }

    return (
        <Button onClick={onClick ? onClick : () => {}} colorScheme={'whiteAlpha'} color={outlined ? "whiteAlpha.800" : 'black'} bgColor={outlined ? "transparent" : 'whiteAlpha.800'} boxShadow="0px 0px 15px rgba(255, 255, 255, 0.64)" style={style}>{children}</Button>
    )
}