import React, {useEffect, useState} from "react";
import axios from 'axios';
import {Box, Button, Divider, Heading, HStack, Stack, Text, VStack} from "@chakra-ui/react";

import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useDispatch} from "react-redux";

const stripePromise = loadStripe('pk_test_51OZlRTGULYki0W1yq4tkqqplpDP39HjHqgMjqec5BIYt8yyPvy48ozk8M06pGkrAs6BzI3IZ95K9XhaPd7QhpeJ800hxXC1lgC');

const Payment = ({selectedService, selectedPackage, contactInformation, selectedDay, selectedTime}) => {

  const [secret, setSecret] = useState();
  const [preTaxPrice, setPreTaxPrice] = useState()
  const [discount, setDiscount] = useState()
  const [finalPrice, setFinalPrice] = useState()
  const [customerId, setCustomerId] = useState('')
  const [customer, setCustomer] = useState()
  const [gettingDiscount, setGettingDiscount] = useState(false);


  async function getPaymentIntent() {
    const createCustomerResponse = await axios.post("/api/customer/createCustomer", {
      firstName: contactInformation.firstName,
      lastName: contactInformation.lastName,
      email: contactInformation.email,
      phone: contactInformation.phone
    })
    const tmpCustomer = createCustomerResponse.data
    setCustomer(tmpCustomer);
    let preTax = selectedPackage.price;
    const tmpDiscount = (selectedPackage.price * .15).toFixed(2);
    setDiscount(tmpDiscount)
    setPreTaxPrice(preTax);
    if (tmpCustomer.isSubscribed && !tmpCustomer.subPromoUsed) {
      preTax = (preTax - (preTax * .15)).toFixed(2)
      setPreTaxPrice(preTax);
      setGettingDiscount(true);
    }
    let postTax = (+(selectedPackage.price * 0.08).toFixed(2) + +preTax).toFixed(2);
    console.log((+selectedPackage.price * 0.08).toFixed(2) + +preTax)
    setFinalPrice(postTax);
    setCustomerId(tmpCustomer._id);
    const response = await axios.get(`/api/stripe/secret?price=${postTax}&customer=${tmpCustomer.stripeId}`);
    setSecret(response.data.client_secret)
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret: secret,
  };

  const formatTime = () => {
    const hour = selectedTime % 12 || 12; // Convert 0 to 12
    const period = selectedTime >= 12 ? "PM" : "AM";
    return `${hour}:00 ${period}`;
  };


  useEffect(() => {
    getPaymentIntent()
  }, []);
  return (
    <Box h={"fit-content"}>
      <Text align={'left'} fontWeight={'bold'} fontSize={'2xl'}>Appointment</Text>
      <Text fontSize={'3xl'}>You are scheduled for {selectedDay} at {formatTime()}!</Text>
      <Text fontSize={'xl'}>Pay now to lock in your time!</Text>
      <Divider mt={25} mb={25}/>

      <HStack justifyContent={'space-between'} wrap={'wrap'}>
        <VStack w={['100%', '45%']} alignItems={'flex-start'}>
          <Heading align={'left'} fontWeight={'bold'} fontSize={['4xl', '2xl']}>Contact Information</Heading>
          <VStack alignItems={'flex-start'} gap={0}>
            <Text fontWeight={'bold'} fontSize={'xl'}>Name:</Text>
            <Text fontSize={'xl'}>{contactInformation.firstName} {contactInformation.lastName}</Text>
          </VStack>
          <VStack alignItems={'flex-start'} gap={0}>
            <Text fontWeight={'bold'} fontSize={'xl'}>Email:</Text>
            <Text fontSize={'xl'}>{contactInformation.email}</Text>
          </VStack>
          <VStack alignItems={'flex-start'} gap={0}>
            <Text fontWeight={'bold'} fontSize={'xl'}>Phone:</Text>
            <Text fontSize={'xl'}>{contactInformation.phone}</Text>
          </VStack>

          <Heading align={'left'} fontWeight={'bold'} fontSize={['4xl', '2xl']}>Package</Heading>
          <VStack alignItems={'flex-start'}>
            <Text fontWeight={'bold'} fontSize={'xl'}>Service:</Text>
            <Text fontSize={'xl'}>{selectedService.name}</Text>
          </VStack>
          <VStack alignItems={'flex-start'}>
            <Text fontWeight={'bold'} fontSize={'xl'}>Package:</Text>
            <Text fontSize={'xl'}>{selectedPackage.name}</Text>
          </VStack>
          <VStack alignItems={'flex-start'}>
            <Text fontWeight={'bold'} fontSize={'xl'}>Price:</Text>
            <Text fontSize={'xl'}>${selectedPackage.price}</Text>
          </VStack>
        </VStack>
        <VStack w={['100%', '45%']}>
          <Heading align={'left'} fontWeight={'bold'} fontSize={['4xl', '2xl']} mb={25}>Payment</Heading>
          <Stack direction={'row'} w={'100%'} justifyContent={'space-between'}>
            <Text fontSize={'lg'} fontWeight={'bold'} align={'left'}>Package Price</Text>
            <Text fontSize={'lg'} fontWeight={'bold'} align={'right'}>${selectedPackage.price}</Text>
          </Stack>

          {gettingDiscount ? <Stack direction={'row'} w={'100%'} justifyContent={'space-between'}>
            <Text fontSize={'lg'} fontWeight={'bold'} align={'left'}>Subscription Discount</Text>
            <Text fontSize={'lg'} fontWeight={'bold'} align={'right'}>- ${discount}</Text>
          </Stack> : <></>}

          <Stack direction={'row'} w={'100%'} justifyContent={'space-between'}>
            <Text fontSize={'lg'} fontWeight={'bold'} align={'left'}>Tax</Text>
            <Text fontSize={'lg'} fontWeight={'bold'}
                  align={'right'}>${(selectedPackage.price * 0.08).toFixed(2)}</Text>
          </Stack>

          <Divider mt={25} mb={5}/>

          <Stack direction={'row'} w={'100%'} justifyContent={'space-between'} mb={5}>
            <Text fontSize={'2xl'} fontWeight={'bold'} align={'left'}>Total</Text>
            <Text fontSize={'2xl'} fontWeight={'bold'}
                  align={'right'}>${finalPrice}</Text>
          </Stack>
          <Box w={"100%"}>
            {secret === undefined ? <Text>Loading...</Text> :
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm secret={secret} selectedDay={selectedDay} selectedTime={selectedTime}
                              selectedService={selectedService} selectedPackage={selectedPackage}
                              contactInformation={contactInformation} customerId={customerId}/>
              </Elements>}
          </Box>
        </VStack>
      </HStack>

    </Box>
  )

}

const CheckoutForm = ({secret, selectedDay, selectedService, selectedTime, customerId, selectedPackage}) => {

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: `http://52.70.13.3/payment-success?date=${selectedDay.replaceAll("/", "-")}&firstName=${contactInformation.firstName}&lastName=${contactInformation.lastName}&email=${contactInformation.email}&phone=${contactInformation.phone}&service=${selectedService.name}&time=${selectedTime}`
        return_url: `http://localhost:3000/payment-success?date=${selectedDay.replaceAll("/", "-")}&customer=${customerId}&service=${selectedService._id}&package=${selectedPackage._id}&time=${selectedTime}`
        // production
        // return_url: `http://3.131.177.133/payment-success?date=${selectedDay.replaceAll("/", "-")}&customer=${customerId}&service=${selectedService._id}&package=${selectedPackage._id}&time=${selectedTime}`
        // return_url: `http://52.70.13.3/payment-success?date=${selectedDay.replaceAll("/", "-")}&firstName=${contactInformation.firstName}&lastName=${contactInformation.lastName}&email=${contactInformation.email}&phone=${contactInformation.phone}&service=${selectedService._id}&package=${selectedPackage._id}&time=${selectedTime}`
      },
    }).then(async ({error}) => {
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      } else {
        setMessage("An unexpected error occurred."); // This might need adjustment
        setIsLoading(false);
      }
    }).catch(error => {
      // Handle any unexpected errors here
      console.error("Error:", error);
      setMessage("An unexpected error occurred.");
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (!stripe) {
      return;
    }
    stripe.retrievePaymentIntent(secret).then(async ({paymentIntent}) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        default:
          console.log(paymentIntent.status)
      }
    });
  }, [stripe]);

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions}/>
      <Button size={'lg'} isDisabled={isLoading || !stripe || !elements} id="submit" mt={25} ml={'auto'}
              colorScheme={'green'} width={200} onClick={handleSubmit}>
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default Payment;
