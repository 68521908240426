import React, {useEffect, useState} from "react";
import {Box, IconButton, Stack, Text} from "@chakra-ui/react";
import Day from "./Day";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";

const Calendar = ({selectedDay, setSelectedDay, selectedService}) => {

    const actualDate = new Date();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentMonthString, setCurrentMonthString] = useState("");
    const [currentMonthNumber, setCurrentMonthNumber] = useState("");
    const [currentYearString, setCurrentYearString] = useState("")
    const [daysOfMonth, setDaysOfMonth] = useState([]);
    const [foundBookings, setFoundBookings] = useState([])


    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

    const getCurrentMonthDays = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const daysArray = [];
        for (let day = 1; day <= daysInMonth; day++) {
            const currentDate = new Date(year, month, day);
            const dayOfWeek = currentDate.toLocaleDateString('en-US', {weekday: 'long'});

            daysArray.push({day, dayOfWeek});
        }

        return daysArray;
    };

    useEffect(() => {
        setCurrentMonthString(currentDate.toLocaleString('default', {month: 'long'}));
        setCurrentMonthNumber(currentDate.getMonth() + 1)
        setDaysOfMonth(getCurrentMonthDays())
        setCurrentYearString(currentDate.toLocaleString('default', {year: 'numeric'}))
    }, [currentDate.getMonth()])

    useEffect(() => {
        let month = (actualDate.getMonth() + 1).toString(); // Adding 1 to get the correct month
        let day = actualDate.getDate().toString(); // Getting the day of the month

// Remove leading zeros if present
        month = parseInt(month, 10).toString();
        day = parseInt(day, 10).toString();
        const year = actualDate.getFullYear();

        const formattedDate = `${month}/${day}/${year}`;
        console.log("formattedDate:", formattedDate); // Output: "2/23/2024" (if today is February 23, 2024)
        setSelectedDay(formattedDate)
    }, []);

    const goToNextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setCurrentDate(newDate);
    }

    const goToLastMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setCurrentDate(newDate);
    }


    const renderCurrentMonth = () => {
        return (
            <>
                {days.map(d => {

                    return (
                        <Stack mb={'auto'}>
                            <Text>{d}</Text>
                            {daysOfMonth.map((dom, index) => {
                                switch (d) {
                                    case "SUN":
                                        if (dom.dayOfWeek === "Sunday") {
                                            if (index < 7) {
                                                if (index === 0) {
                                                    return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                                setSelectedDay={setSelectedDay}
                                                                selectedDay={selectedDay} dom={dom}
                                                                date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}
                                                                disabled/>
                                                } else {
                                                    return <>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             dom={""} disabled/>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             dom={dom}
                                                             date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}
                                                             date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}
                                                             disabled/>
                                                    </>
                                                }
                                            } else {
                                                return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                            setSelectedDay={setSelectedDay}
                                                            selectedDay={selectedDay} dom={dom}
                                                            date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}
                                                            disabled/>
                                            }
                                        }
                                        break;
                                    case "MON":
                                        if (dom.dayOfWeek === "Monday") {
                                            if (index < 7) {
                                                if (index === 0 || index === 1) {
                                                    return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                                setSelectedDay={setSelectedDay}
                                                                selectedDay={selectedDay} dom={dom}
                                                                date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                } else {
                                                    return <>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             disabled dom={""}/>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             dom={dom}
                                                             date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                    </>
                                                }
                                            } else {
                                                return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                            setSelectedDay={setSelectedDay}
                                                            selectedDay={selectedDay} dom={dom}
                                                            date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                            }
                                        }
                                        break;
                                    case "TUE":
                                        if (dom.dayOfWeek === "Tuesday") {
                                            if (index < 7) {
                                                if (index === 0 || index === 1 || index === 2) {
                                                    return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                                setSelectedDay={setSelectedDay}
                                                                selectedDay={selectedDay} dom={dom}
                                                                date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                } else {
                                                    return <>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             disabled dom={""}/>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             dom={dom}
                                                             date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                    </>
                                                }
                                            } else {
                                                return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                            setSelectedDay={setSelectedDay}
                                                            selectedDay={selectedDay} dom={dom}
                                                            date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                            }
                                        }
                                        break;
                                    case "WED":
                                        if (dom.dayOfWeek === "Wednesday") {
                                            if (index < 7) {
                                                if (index === 0 || index === 1 || index === 2 || index === 3) {
                                                    return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                                setSelectedDay={setSelectedDay}
                                                                selectedDay={selectedDay} dom={dom}
                                                                date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                } else {
                                                    return <>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             disabled dom={""}/>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             dom={dom}
                                                             date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                    </>
                                                }
                                            } else {
                                                return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                            setSelectedDay={setSelectedDay}
                                                            selectedDay={selectedDay} dom={dom}
                                                            date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                            }
                                        }
                                        break;
                                    case "THU":
                                        if (dom.dayOfWeek === "Thursday") {
                                            if (index < 7) {
                                                if (index === 0 || index === 1 || index === 2 || index === 3 || index === 4) {
                                                    return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                                setSelectedDay={setSelectedDay}
                                                                selectedDay={selectedDay} dom={dom}
                                                                date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                } else {
                                                    return <>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             disabled dom={""}/>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             dom={dom}
                                                             date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                    </>
                                                }
                                            } else {
                                                return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                            setSelectedDay={setSelectedDay}
                                                            selectedDay={selectedDay} dom={dom}
                                                            date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                            }
                                        }
                                        break;
                                    case "FRI":
                                        if (dom.dayOfWeek === "Friday") {
                                            if (index < 7) {
                                                if (index === 0 || index === 1 || index === 2 || index === 3 || index === 4 || index === 5) {
                                                    return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                                setSelectedDay={setSelectedDay}
                                                                selectedDay={selectedDay} dom={dom}
                                                                date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                } else {
                                                    return <>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             disabled dom={""}/>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             dom={dom}
                                                             date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                    </>
                                                }
                                            } else {
                                                return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                            setSelectedDay={setSelectedDay}
                                                            selectedDay={selectedDay} dom={dom}
                                                            date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                            }
                                        }
                                        break;
                                    case "SAT":
                                        if (dom.dayOfWeek === "Saturday") {
                                            if (index < 7) {
                                                if (index === 0 || index === 1 || index === 2 || index === 3 || index === 4 || index === 5 || index === 6) {
                                                    return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                                setSelectedDay={setSelectedDay}
                                                                selectedDay={selectedDay} dom={dom}
                                                                date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                } else {
                                                    return <>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             disabled dom={""}/>
                                                        <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                             setSelectedDay={setSelectedDay}
                                                             selectedDay={selectedDay}
                                                             dom={dom}
                                                             date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                                    </>
                                                }
                                            } else {
                                                return <Day currentDay={dom.day} currentMonth={currentMonthNumber}
                                                            setSelectedDay={setSelectedDay}
                                                            selectedDay={selectedDay} dom={dom}
                                                            date={`${currentMonthNumber}/${dom.day}/${currentYearString}`}/>
                                            }
                                        }
                                        break;
                                }
                            })}
                        </Stack>
                    )
                })}
            </>
        )
    }





    return (
        <Box w={{base: '40%', xs: '100%', sm: '100%', lg: '40%'}} m={'auto'} h={'fit-content'}>
            <Stack direction={'row'} bgColor={'white'} boxShadow={'2xl'} borderRadius={5} p={3} mb={5}
                   justifyContent="space-evenly" alignItems="center">
                <IconButton isRound={true} mr={'auto'} bgColor={'transparent'}
                            icon={<ChevronLeftIcon boxSize={8} color={'#595959'}/>} onClick={goToLastMonth}
                            isDisabled={currentMonthNumber === 1}/>
                <Text fontWeight={'bold'} fontSize={'xl'}
                      color={'#595959'}>{currentMonthString} - {currentYearString}</Text>
                <IconButton isRound={true} ml={'auto'} bgColor={'transparent'}
                            icon={<ChevronRightIcon boxSize={8} color={'#595959'}/>} onClick={goToNextMonth}/>
            </Stack>
            <Box bgColor={'white'} boxShadow={'2xl'} borderRadius={5} p={3}>
                <Stack direction={'row'} justifyContent="space-evenly" alignItems="center">
                    {/*{(actualDate.getMonth() + 1) === currentMonthNumber ? renderCurrentMonth() : null}*/}
                    {/*{(actualDate.getMonth() + 1) < currentMonthNumber ? renderFutureMonth() : null}*/}
                    {renderCurrentMonth()}
                </Stack>
            </Box>
        </Box>
    )
}

export default Calendar;