import {Heading, Table, TableContainer, Tbody, Th, Thead, Tr, useToast, VStack} from "@chakra-ui/react";
import React from "react";
import CustomerRow from "./CustomerRow";


export default function PurchasedPackageTable({customer}) {
    const toast = useToast()


    return (
        <VStack mb={'auto'}>
            <Heading fontSize={'3xl'}>Purchased Packages</Heading>
            <TableContainer w={'fit-content'} bgColor={'lightGrey'} borderRadius={10}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Service</Th>
                            <Th>Package</Th>
                            <Th isNumeric>Sessions Left</Th>
                            <Th textAlign={'right'}>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {customer.purchasedPackages.map(pkg =>
                            <CustomerRow customer={customer} pkg={pkg}/>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    )
}