import React from 'react'
import {Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";


export default function FoundBookingsTable({foundBookings}) {

    const renderBooking = (booking) => {
        if (booking.customer) {

            return (
                <Tr>
                    <Td>{booking.customer.firstName}</Td>
                    <Td>{booking.customer.lastName}</Td>
                    <Td>{booking.customer.email}</Td>
                    <Td>{booking.customer.phone}</Td>
                    <Td>{booking.service.name}</Td>
                    <Td>{booking.date}</Td>
                    <Td>{booking.time}</Td>
                </Tr>
            )
        }
    }

    return (
        <Box h={'100%'}>
            <Heading p={4}>Found Bookings</Heading>
            <TableContainer w={'95%'} m={'auto'} h={800} overflowY={'auto'} variant={''} bgColor={'white'} borderRadius={5} boxShadow={'xl'}>
                <Table variant='striped'>
                    <Thead>
                        <Tr>
                            <Th>First Name</Th>
                            <Th>Last Name</Th>
                            <Th>Email</Th>
                            <Th>Phone Number</Th>
                            <Th>Service</Th>
                            <Th>Date</Th>
                            <Th>Time</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {foundBookings.length > 0 ? <>{foundBookings.map(booking => renderBooking(booking))}</> : undefined}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}