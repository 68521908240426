import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    useToast,
    VStack
} from "@chakra-ui/react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {SET_USER} from "../../utils/actions";


export default function MyAdminAccount({admin}) {
    const dispatch = useDispatch();
    const toast = useToast()
    const [firstName, setFirstName] = useState(admin.firstName)
    const [lastName, setLastName] = useState(admin.lastName)
    const [email, setEmail] = useState(admin.email)
    const [phone, setPhone] = useState(admin.phone)
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [passwordMatch, setPasswordMatch] = useState();

    const handleFirstNameChange = (e) => setFirstName(e.target.value)
    const handleLastNameChange = (e) => setLastName(e.target.value)
    const handleEmailChange = (e) => setEmail(e.target.value)
    const handlePhoneChange = (e) => setPhone(e.target.value)
    const handlePasswordChange = (e) => setPassword(e.target.value)
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value)

    const updateAdmin = async () => {
        const data = {
            firstName: firstName === admin.firstName ? null : firstName,
            lastName: lastName === admin.lastName ? null : lastName,
            email: email === admin.email ? null : email,
            phone: phone === admin.phone ? null : phone,
            password: (confirmPassword === password) && (password !== "") ? password : null
        }

        const response = await axios.patch(`/api/admins/updateAdmin/${admin._id}`, data);
        if(response.status === 200){
            const updatedAdmin = await axios.get(`/api/admins/${admin._id}`);
            dispatch({
                type: SET_USER,
                user: updatedAdmin.data
            })
            toast({
                title: 'Account Updated Successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        console.log(password === confirmPassword)
        if (password === confirmPassword) {
            setPasswordMatch(true);
        } else{
            setPasswordMatch(false)
        }
    }, [password, confirmPassword]);


    return (
        <Box w={'70%'} m={'auto'} h={'100vh'}>
            <Heading align={'left'} fontSize={'6xl'}>Hello, {admin.firstName}</Heading>
            <VStack gap={3} w={'50%'} m={'auto'} mt={25} mb={25}>
                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input value={firstName} onChange={handleFirstNameChange} placeholder={"First Name"}
                           bgColor={'white'}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input value={lastName} onChange={handleLastNameChange} placeholder={"Last Name"}
                           bgColor={'white'}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input value={email} onChange={handleEmailChange} placeholder={"Email"} bgColor={'white'}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Phone Number</FormLabel>
                    <Input value={phone} onChange={handlePhoneChange} placeholder={"Phone Number"} bgColor={'white'}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Change Password</FormLabel>
                    <Input value={password} onChange={handlePasswordChange} type={'password'}
                           placeholder={"Change Password"} bgColor={'white'}/>
                </FormControl>
                <FormControl isInvalid={!passwordMatch}>
                    <FormLabel>Confirm Password</FormLabel>
                    <Input value={confirmPassword} onChange={handleConfirmPasswordChange} type={'password'}
                           placeholder={"Confirm Password"} bgColor={'white'}/>
                    <FormErrorMessage>Passwords do not match.</FormErrorMessage>
                </FormControl>
            </VStack>
            <Button isDisabled={!passwordMatch} colorScheme={'blackAlpha'} bgColor={'black'} color={'white'} onClick={updateAdmin}>Save
                Changes</Button>
        </Box>
    )
}