import React from 'react';
import {Text, useDisclosure, VStack} from "@chakra-ui/react";
import BookingDrawer from "./BookingDrawer";
import {formatTime} from "../../../utils/helpers";


export default function BookingCard({booking, getBookings}) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const btnRef = React.useRef()


    return (
        <VStack ref={btnRef} onClick={onOpen} w={'95%'} borderRadius={15} border={`2px solid`} borderColor={'blue.400'}
                color={'blue.600'}
                h={'fit-content'}
                bgColor={'blue.200'} p={2} alignItems={'flex-start'} gap={0} mt={1} shadow={"xl"}
                cursor={'pointer'}>
            <Text>{booking.service.name} - {formatTime(booking.time)}</Text>
            <Text>{booking.customer.firstName} {booking.customer.lastName}</Text>
            <BookingDrawer booking={booking} btnRef={btnRef} getBookings={getBookings} isOpen={isOpen}
                           onClose={onClose}/>
        </VStack>
    )
}