import React, {useState} from 'react';
import {Alert, AlertDescription, AlertIcon, Box, Button, Card, HStack, Img, Input, VStack} from "@chakra-ui/react";
import logo from "../../assets/logo-black.png";
import BlackButton from "../../components/Buttons/BlackButton";
import axios from "axios";
import {Form, useNavigate} from "react-router-dom";

export default function SignIn() {

    const [showError, setShowError] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phone: ""
    })
    const [showLogin, setShowLogin] = useState(true)

    const navigate = useNavigate()

    const handleLogin = async () => {
        try {
            const response = await axios.post('/api/customer/login', {email: user.email, password: user.password})
            console.log(response.status);
            const loggedInUser = response.data;
            if (showError) {
                setShowError(false)
            }
            if (!loggedInUser.isAdmin) {
                setShowError(true)
            } else {
                navigate('/customer-dashboard')
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setShowError(true)
                console.log('Error message:', error.response.data.message);
            }
        }

    }

    const handleRegister = async () => {
        const response = await axios.post('/api/customer/', user);
        console.log(response.data)
    }

    return (
        <Box mt={75} h={500}>
            <Card w={500} h={'fit-content'} m={'auto'} p={5}>
                <VStack>
                    <Img src={logo} w={200} m={'auto'} mb={5}/>
                    <Form onSubmit={!showLogin ? handleRegister : handleLogin}>

                        <Input placeholder={'Email'} type={'email'} value={user.email}
                               onChange={e => setUser({...user, email: e.target.value})}/>
                        <Input placeholder={'Password'} type={'password'} value={user.password}
                               onChange={e => setUser({...user, password: e.target.value})}/>
                        {!showLogin ? <>
                            <Input placeholder={"First Name"} value={user.firstName}
                                   onChange={e => setUser({...user, firstName: e.target.value})}/>
                            <Input placeholder={"Last Name"} value={user.lastName}
                                   onChange={e => setUser({...user, lastName: e.target.value})}/>
                            <Input placeholder={"Phone Number"} value={user.phone}
                                   onChange={e => setUser({...user, phone: e.target.value})}/>
                        </> : <></>}
                        <HStack justifyContent={'space-between'} w={'100%'}>
                            <Button colorScheme={'teal'}
                                    onClick={() => setShowLogin(!showLogin)}>{!showLogin ? "Sign In" : "Create Account"}</Button>
                            {!showLogin ? <BlackButton type={'submit'} onClick={handleRegister}>Register</BlackButton> :
                                <BlackButton type={'submit'} onClick={handleLogin}>Login</BlackButton>}


                        </HStack>
                    </Form>

                    {showError ? <Alert status={'error'}>
                        <AlertIcon/>
                        <AlertDescription>Invalid login credentials.</AlertDescription>
                    </Alert> : <></>}
                </VStack>
            </Card>
        </Box>
    )
}
