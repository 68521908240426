import React, {useState} from 'react';
import {
    Button, Center,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Spinner, useToast,
    VStack
} from "@chakra-ui/react";
import axios from "axios";
import {formatPhoneNumber} from "../../../utils/helpers";


export default function AddAdminModal({showAddAdmin, closeAddAdminModal, getAdmins}) {

    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const handleFirstNameChange = (e) => setFirstName(e.target.value)
    const handleLastNameChange = (e) => setLastName(e.target.value)
    const handleEmailChange = (e) => setEmail(e.target.value)
    const handlePhoneChange = (e) => setPhone(formatPhoneNumber(e.target.value))

    const addAdmin = async () => {
        setLoading(true)
        const data = {
            firstName, lastName, email, phone
        }
        const createdAdmin = await axios.post(`/api/admins/`, data);
        if(createdAdmin.status === 200){
            toast({
                title: 'Admin Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            getAdmins()
            setLoading(false)
            closeAddAdminModal();
            setFirstName("")
            setLastName("")
            setEmail("")
            setPhone("")
        } else {
            console.log(createdAdmin)
        }
    }

    return (
        <Modal isOpen={showAddAdmin} onClose={closeAddAdminModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader fontSize={'2xl'}>Add Admin</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    {loading && ( // Conditionally render the spinner when loading is true
                        <Center
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            zIndex={10}
                            background="rgba(255, 255, 255, 0.5)" // Adjust the background color and opacity
                        >
                            <Spinner />
                        </Center>
                    )}
                    <VStack gap={5}>
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input placeholder={'First Name'} value={firstName} onChange={handleFirstNameChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Input placeholder={'Last Name'} value={lastName} onChange={handleLastNameChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input placeholder={'Email'} value={email} onChange={handleEmailChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone</FormLabel>
                            <Input placeholder={'Phone Number'} value={phone} onChange={handlePhoneChange}/>
                        </FormControl>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='green' mr={3} fontWeight={'bold'} onClick={addAdmin}>
                        Add Admin
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}