import React from "react";
import {Box, Divider, HStack, Img, Link, Stack, Text, VStack} from "@chakra-ui/react";
import logo from "../../assets/footerLogo.png";
import insta from "../../assets/instagram-icon.svg";
import fb from "../../assets/facebook-icon.svg";
import x from "../../assets/x-icon.svg";
import google from "../../assets/google.svg";
import {useSelector} from "react-redux";

const Footer = () => {

    const {services} = useSelector(state => state);
    return (
        <VStack
            h={300}
            bgColor="teal.900"
            justifyContent="center"
            alignItems="center"
            // position="absolute"
            // bottom={0}
            width="100%"
        >
            <HStack width="80%" m="auto" style={{marginTop: "auto"}} justifyContent={'space-evenly'}
                   alignItems={'center'} color={'white'}>
                <VStack gap={-2}>
                    <Img src={logo} w={500} m="auto" mt={5} ml={0}/>
                    <HStack justifyContent={'space-evenly'} w={'80%'} alignItems={'center'}>
                        <Img cursor={'pointer'} src={insta} h={'45px'}/>
                        <Img cursor={'pointer'} src={fb} h={'45px'}/>
                        <Img cursor={'pointer'} src={x} h={'45px'}/>
                    </HStack>
                </VStack>
                <Box display={["none", "flex"]} w={'50%'} justifyContent={'space-evenly'} mt={5}>
                    <VStack color={'white'} gap={5}>
                        <Link href={"/"}>Home</Link>
                        <Link href={'/about-us'}>About Us</Link>
                    </VStack>
                    <Divider h={'80%'} orientation={'vertical'} color={'blue.500'}/>
                    <VStack color={'white'}>
                        <Text fontWeight={'bold'}>Services</Text>
                        <VStack alignItems={'flex-start'}>
                            {services.map(s => <Link>{s.name}</Link>)}
                        </VStack>
                    </VStack>
                    <Divider h={'80%'} orientation={'vertical'}/>
                    <VStack gap={5}>
                        <Text fontWeight={'bold'}>Find Us!</Text>
                        <VStack alignItems={'flex-start'}>
                            <Text align={'left'}>Salon Lofts</Text>
                            <Text>23541 Westheimer Pkwy, Katy, TX 77494</Text>
                        </VStack>
                        <Img cursor={'pointer'} src={google} h={'45px'}/>
                    </VStack>
                </Box>
            </HStack>
            <Text color={'white'}>
                Copyright © 2023 Slim Body Contouring And Laser Lipo - All Rights Reserved.
            </Text>
        </VStack>
    )
        ;
};

export default Footer;
