import React, {useEffect, useState} from 'react';
import {Accordion, Box, Heading, HStack, SimpleGrid, Text} from "@chakra-ui/react";
import axios from "axios";
import CustomerAccordion from "./CustomerAccordion";
import CustomerSearchForm from "../../../components/CustomerSearchForm";
import API from "../../../utils/api";

export default function Customers() {
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState({
        firstName: null,
        lastName: null,
        email: null,
        phone: null
    })

    const getCustomers = async () => {
        try {
            const response = await axios.get("/api/customer/");
            setCustomers(response.data);
        } catch (error) {
            console.error("Error fetching customers:", error);
        }
    }

    const findCustomer = async () => {
        const data = {};
        if (customer.firstName) data.firstName = customer.firstName;
        if (customer.lastName) data.lastName = customer.lastName;
        if (customer.email) data.email = customer.email;
        if (customer.phone) data.phone = customer.phone;

        const tmpCustomers = await API.findCustomers(data)
        setCustomers(tmpCustomers)
    }

    useEffect(() => {
        getCustomers();
    }, []);

    return (
        <Box w='100%' m='auto' h={'100%'} mb={'auto'}>
            <HStack mt={50} justifyContent={'space-between'} h={'100%'}>
                <Box w={'20%'} m={'auto'} h={'100%'} mt={0}>
                    <CustomerSearchForm heading={"Find Customer"} customer={customer} setCustomer={setCustomer}
                                        handleSearch={findCustomer}/>
                </Box>
                <Box w={'65%'} m={'auto'} mt={2} ml={0}>
                    <Heading fontSize={'5xl'} fontWeight={'normal'} align={'left'}>Customers</Heading>
                    <Accordion allowToggle bgColor={'white'} borderRadius={10}>
                        <Box pt={2} pb={2} w={'100%'}>
                            <SimpleGrid columns={4} w={'100%'} m={'auto'} textAlign={'left'} pl={20} pr={20}>
                                <Text align={'left'}>First Name</Text>
                                <Text>Last Name</Text>
                                <Text>Email</Text>
                                <Text align={'center'}>Phone Number</Text>
                            </SimpleGrid>
                        </Box>
                        {customers.map((customer, index) => <CustomerAccordion customer={customer} index={index}
                                                                               getCustomers={getCustomers}/>)}
                    </Accordion>
                </Box>
            </HStack>
        </Box>
    )
}
