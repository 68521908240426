import React from 'react'
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button, Heading,
    HStack,
    SimpleGrid,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr, VStack
} from "@chakra-ui/react";
import EditCustomerForm from "./EditCustomerForm";
import PurchasedPackageTable from "./PurchasedPackageTable";


export default function CustomerAccordion({customer, index, getCustomers}) {

    console.log(customer)

    return (
        <AccordionItem key={index}>
            <AccordionButton justifyContent={'space-between'} w={'100%'} m={'auto'} textAlign={'left'} pl={20} pr={20}>
                <SimpleGrid columns={4} w={'100%'}>
                    <Text align={'left'}>{customer.firstName}</Text>
                    <Text>{customer.lastName}</Text>
                    <Text>{customer.email}</Text>
                    <Text align={'center'}>{customer.phone}</Text>
                </SimpleGrid>
                <AccordionIcon/>
            </AccordionButton>
            <AccordionPanel pb={4}>
                <HStack justifyContent={'space-evenly'}>
                    <PurchasedPackageTable customer={customer}/>
                    <EditCustomerForm customer={customer} getCustomers={getCustomers}/>
                </HStack>
            </AccordionPanel>
        </AccordionItem>
    )
}