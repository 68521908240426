import React from 'react';
import {Button} from "@chakra-ui/react";


export default function BlackButton({children, w, onClick, type}){

    const style = {
        width: w ? w : 'fit-content'
    }

    return (
        <Button type={type} onClick={onClick} colorScheme={'blackAlpha'} color={'white'} bgColor={'blackAlpha.800'} boxShadow="0px 0px 15px rgba(255, 255, 255, 0.64)" style={style}>{children}</Button>
    )
}