import React, {useState} from 'react';
import {Box, Button, FormControl, FormLabel, Heading, HStack, Input, VStack} from "@chakra-ui/react";
import {formatPhoneNumber} from "../../../utils/helpers";
import API from "../../../utils/api";
import FoundBookingsTable from "./FoundBookingsTable";
import CustomerSearchForm from "../../../components/CustomerSearchForm";

export default function FindBooking() {

    const [foundBookings, setFoundBookings] = useState([])

    const [customer, setCustomer] = useState({
        firstName: null,
        lastName: null,
        email: null,
        phone: null
    })

    const findBookings = async () => {
        const data = {};
        if (customer.firstName) data.firstName = customer.firstName;
        if (customer.lastName) data.lastName = customer.lastName;
        if (customer.email) data.email = customer.email;
        if (customer.phone) data.phone = customer.phone;

        const bookings = await API.findBookings(data)
        console.log(bookings)
        setFoundBookings(bookings)
    }


    return (
        <HStack justifyContent={'flex-start'} w={'80%'} margin={'auto'} mt={20}>
            <CustomerSearchForm heading={"Find Bookings"} customer={customer} setCustomer={setCustomer} handleSearch={findBookings}/>
            <Box w={'70%'} mb={'auto'}>
                {foundBookings.length > 0 ? <FoundBookingsTable foundBookings={foundBookings}/> : <Heading>No Found Bookings.</Heading>}
            </Box>
        </HStack>
    )
}


