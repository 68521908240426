import React, {useEffect, useRef, useState} from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button, Divider,
    Heading,
    Input,
    Text,
    Textarea,
    useToast,
    VStack
} from "@chakra-ui/react";
import axios from "axios";
import ServiceAccordion from "./ServiceAccordion";
import API from "../../../utils/api";


export default function Index() {
    const [services, setServices] = useState([]);
    const [newServiceName, setNewServiceName] = useState("")
    const [newServiceBrief, setNewServiceBrief] = useState("")
    const [newServiceDescription, setNewServiceDescription] = useState("")
    const toast = useToast()

    const handleNameChange = (e) => setNewServiceName(e.target.value)
    const handleBriefChange = (e) => setNewServiceBrief(e.target.value)
    const handleDescriptionChange = (e) => setNewServiceDescription(e.target.value)
    const getServices = async () => {
        const services = await API.getServices();
        setServices(services);
    }

    function renderServices() {
        return (
            <Accordion allowToggle bgColor={'#f3f3f3'} borderRadius={10}>
                {services.map(service => {
                    return (
                        <ServiceAccordion service={service} getServices={getServices}/>
                    )
                })
                }
            </Accordion>
        )
    }

    async function createService() {
        const newService = {
            name: newServiceName,
            brief: newServiceBrief,
            description: newServiceDescription
        }
        console.log(newService)
        const createdService = await axios.post("/api/service", newService);
        if (createdService.status === 200) {
            toast({
                title: 'Service Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            getServices()
            setNewServiceName("")
            setNewServiceBrief("")
            setNewServiceDescription("")
        } else {
            toast({
                title: 'Error Creating Service',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        getServices();
    }, []);
    return (
        <Box w={'75%'} margin={'auto'} mt={25} h={'100vh'}>
            <Heading fontSize={'5xl'} fontWeight={'normal'} align={'left'}>Services</Heading>
            {services.length > 0 ? renderServices() : <Heading>No Services Created.</Heading>}
            <VStack w={'40%'} m={'auto'} mt={100} >
                <Heading fontWeight={'normal'}>Add Service</Heading>
                <Input variant={'solid'} value={newServiceName} placeholder={'Service Name'} onChange={handleNameChange}/>
                <Textarea variant={'solid'} value={newServiceBrief} placeholder={'Brief Description'} onChange={handleBriefChange}/>
                <Textarea variant={'solid'} value={newServiceDescription} placeholder={'Service Description'} onChange={handleDescriptionChange}/>
                <Button onClick={createService}>Create</Button>
            </VStack>
        </Box>
    )
}