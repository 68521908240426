import React, {useEffect, useState} from "react";
import TimeCard from "./TimeCard";
import { Grid, GridItem, Stack } from "@chakra-ui/react";
import axios from "axios";

const TimeSlots = ({selectedTime, setSelectedTime, selectedDay, selectedService}) => {

    const times = [8, 9, 10, 11, 12, 1, 2, 3, 4];
    const [foundBookings, setFoundBookings] = useState([])

    const getBookings = async () => {
        if(selectedDay !== null){
            const foundBookings = await axios.get(`/api/bookings/find?date=${selectedDay}`)
            console.log(foundBookings.data)
            setFoundBookings(foundBookings.data)
        }
    }

    useEffect(() => {
        getBookings()
    }, [selectedDay]);

    return (
        <Grid
            templateColumns="repeat(3, 1fr)" // 3 columns
            gap={4} // Set gap between grid items
            p={4} // Add padding to the grid
            w={{base: 'fit-content', xs: '10%'}}
            m={'auto'}
        >
            {/* First Row */}
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={8} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={11} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={14} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>

            {/* Second Row */}
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={9} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={12} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={15} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>

            {/* Third Row */}
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={10} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={13} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={16} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>

            {/* Fourth Row */}
            <GridItem colSpan={2} />
            <GridItem>
                <TimeCard foundBookings={foundBookings} time={17} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </GridItem>
        </Grid>
    );
};

export default TimeSlots;
