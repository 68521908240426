import React, {useState} from 'react'
import {Box, Card, Heading, HStack, Link, Text, VStack} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {SET_INFO_SERVICE} from "../../../utils/actions";

export default function Services() {

    const {services} = useSelector(state => state);

    return (
        <Box w={'85%'} m={'auto'} mt={100}>
            <Heading fontWeight={'normal'} fontSize={'7xl'}>Services</Heading>
            <HStack wrap={'wrap'} gap={20} justifyContent={'center'} alignItems={'center'} mt={25}>
                {services.map(s => <ServiceCard service={s}/>)}
            </HStack>
        </Box>
    )
}

const ServiceCard = ({service}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [spread, setSpread] = useState(15)
    const [opacity, setOpacity] = useState(.5)

    const handleMouseEnter = () => {
        setSpread(30);
        setOpacity(1)
    };

    const handleMouseLeave = () => {
        setSpread(15);
        setOpacity(.5)
    };

    const handleClick = () => {
        dispatch({
            type: SET_INFO_SERVICE,
            infoService: service
        })
        navigate(`/service/${service.name.replaceAll(" ", "-")}`);
    }

    return (
        <Card h={200} w={300} p={5} boxShadow={`0px 0px ${spread}px rgba(0, 0, 0, ${opacity})`}
              onMouseEnter={handleMouseEnter}
              transition="box-shadow 0.3s ease"
              onMouseLeave={handleMouseLeave}
        >
            <VStack alignItems={'flex-start'}>
                <Text fontSize={'2xl'} align={'left'}>{service.name}</Text>
                <Text align={'left'}>{service.brief}</Text>
                <Link color={"#2B6CB0"} onClick={handleClick} align={'left'}>Find out more</Link>
            </VStack>
        </Card>
    )
}