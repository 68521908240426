import React, {useEffect, useState} from 'react';
import {Card, Heading, Text, VStack} from "@chakra-ui/react";
import axios from "axios";
import BookingCard from "./BookingCard";


export default function DayCard({day, date}) {

    const [bookings, setBookings] = useState([])

    async function getBookings() {
        const response = await axios.get(`/api/bookings/find?date=${date.replaceAll("/", "-")}`)
        const data = response.data;
        setBookings(data);
    }

    useEffect(() => {
        getBookings()
    }, []);

    return (
        <Card minW={235} maxW={265} h={500}>
            <Heading>{day}</Heading>
            <Text>{date}</Text>
            <VStack h={'100%'} overflowY={'auto'} justifyContent={"start"} gap={0} spacing={0}    css={{
                "&::-webkit-scrollbar": {
                    width: "5px" // Adjust scrollbar width for webkit browsers (Chrome, Safari)
                },
                msOverflowStyle: "none", // Hide scrollbar for IE and Edge
                scrollbarWidth: "thin" // Adjust scrollbar width for Firefox
            }}>
                {bookings.length > 0 ? <>{bookings.map(b => <BookingCard getBookings={getBookings} booking={b}/>)}</> :
                    <Text>No Bookings.</Text>}
            </VStack>
        </Card>
    )
}