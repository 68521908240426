import React, {useState} from 'react'
import axios from "axios";
import {
    Button, HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Spinner,
    Td,
    Tr, useDisclosure, useToast
} from "@chakra-ui/react";
import Calendar from "../../Scheduling/Calandar";
import TimeSlots from "../../Scheduling/TimeSlots";


export default function CustomerRow({customer, pkg}){
    const toast = useToast();
    const {isOpen, onOpen, onClose} = useDisclosure()
    const today = new Date();

    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [bookedSessions, setBookedSessions] = useState(pkg.bookedSessions)
    const [sessionsLeft, setSessionsLeft] = useState(+pkg.maxSessions - +bookedSessions);

    const scheduleBooking = async () => {
        onClose()
        const data = {
            date: selectedDay.replaceAll("/", "-"),
            time: selectedTime,
            service: pkg.package.service._id,
            customer: customer._id,
            pkg: pkg,
            paymentSecret: pkg.paymentSecret
        }
        const createdBooking = await axios.post("/api/bookings/", data);

        const addedBooking = await axios.put(`/api/purchased/addBooking/${pkg._id}/${createdBooking.data._id}`)

        if (createdBooking.status === 200 && addedBooking.status === 200) {
            toast({
                title: "Booking Created",
                description: "Created booking and sent conformation email",
                status: "success",
                duration: 5000, // Display for 3 seconds
                isClosable: true
            });
            setSessionsLeft(sessionsLeft - 1)
        }
    }

    return (
        <Tr>
            <Td>{pkg.package.service.name}</Td>
            <Td>{pkg.package.name}</Td>
            <Td isNumeric>{sessionsLeft}</Td>
            <Td textAlign={'right'}>
                <Button size={'sm'} colorScheme={'blue'} isDisabled={sessionsLeft === 0} onClick={onOpen}>Book Next
                    Session</Button>
                {/*<Button size={'sm'} colorScheme={'blackAlpha'} bgColor={'black'}>Book Next Session</Button>*/}
            </Td>
            <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Schedule a Booking for {pkg.package.service.name}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <HStack w={'100%'}>
                            <Calendar selectedService={pkg.package.service}
                                      selectedDay={selectedDay}
                                      setSelectedDay={setSelectedDay}/>
                            <TimeSlots selectedDay={selectedDay}
                                       selectedService={pkg.package.service}
                                       selectedTime={selectedTime}
                                       setSelectedTime={setSelectedTime}/>
                        </HStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='green' mr={3} onClick={scheduleBooking} >
                            Schedule Booking
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Tr>
    )
}