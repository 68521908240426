import React, { useEffect, useState } from 'react';
import {Box, HStack, Spinner} from "@chakra-ui/react";
import AdminNav from "./AdminNav";
import Bookings from "./Bookings/Bookings";
import Index from "./ServiceSection";
import AdminTable from "./AdminTable";
import MyAdminAccount from "./MyAdminAccount";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {SET_USER} from "../../utils/actions";
import Customers from "./Customers";

export default function AdminDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state);
    const [adminNav, setAdminNav] = useState('Bookings');
    const [loading, setLoading] = useState(true);

    const verifyAdmin = async () => {
        try {
            const response = await axios.get('/api/admins/authenticate');
            setLoading(false);
            if(user === null){
                dispatch({
                    type: SET_USER,
                    user: response.data
                })
            }
        } catch (e) {
            navigate("/");
        }
    }

    useEffect(() => {
        verifyAdmin();
    }, []);

    function renderView() {
        switch (adminNav) {
            case "Bookings":
                return <Bookings />;
            case "Services":
                return <Index />;
            case "Admins":
                return <AdminTable />;
            case "My Account":
                return <MyAdminAccount admin={user} />;
            case "Customers":
                return <Customers/>;
            default:
                return null;
        }
    }

    return (
        <HStack h={'fit-content'}>
            <AdminNav adminNav={adminNav} setAdminNav={setAdminNav} />
            <Box w={'100%'} m={'auto'} h={'fit-content'} mt={10}>
                {loading ? <Spinner size="xl" /> : renderView()}
            </Box>
        </HStack>
    );
}
