import React, {useState} from 'react';
import {
    Badge,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormLabel,
    Input,
    Text,
    Textarea,
    useToast,
    VStack
} from "@chakra-ui/react";
import axios from "axios";
import {formatPhoneNumber} from '../../../utils/helpers';
import API from "../../../utils/api";

export default function BookingDrawer({isOpen, onClose, btnRef, booking, getBookings}) {

    const toast = useToast();
    const [firstName, setFirstName] = useState(booking.customer.firstName)
    const [lastName, setLastName] = useState(booking.customer.lastName)
    const [email, setEmail] = useState(booking.customer.email)
    const [phone, setPhone] = useState(formatPhoneNumber(booking.customer.phone))

    const handleFirstNameChange = (e) => setFirstName(e.target.value)
    const handleLastNameChange = (e) => setLastName(e.target.value)
    const handleEmailChange = (e) => setEmail(e.target.value)
    const handlePhoneChange = (e) => {
        if(e.target.value.length <= 18){
            setPhone(formatPhoneNumber(e.target.value))
        }
    }


    const updateBooking = async () => {
        const data = {
            firstName: firstName === booking.customer.firstName ? null : firstName,
            lastName: lastName === booking.customer.lastName ? null : lastName,
            email: email === booking.customer.email ? null : email,
            phone: phone === booking.customer.phone ? null : phone,
        }
        console.log(booking._id)
        const response = await axios.patch(`/api/bookings/update/${booking._id}`, data);
        if (response.status === 200) {
            toast({
                title: 'Booking Updated',
                status: 'info',
                duration: 3000,
                isClosable: true,
            })
            getBookings();
            onClose();
        } else {
            toast({
                title: 'Error updating booking',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const cancelBooking = async () => {
        const canceledBooking = await API.cancelBooking(booking._id)
        getBookings();
        onClose();
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay/>
            <DrawerContent>
                <DrawerCloseButton/>
                <DrawerHeader>Booking Details</DrawerHeader>

                <DrawerBody>
                    <VStack alignItems={'flex-start'} gap={5}>
                        <Box>
                            <Text fontSize={'xl'} fontWeight={'bold'}>{booking.service.name}</Text>
                            <Badge colorScheme={'yellow'}>Confirmation Pending</Badge>
                        </Box>customer.
                        <Box>
                            <Text><strong>Customer :</strong> {booking.customer.firstName} {booking.customer.lastName}</Text>
                            <Text><strong>Email :</strong> {booking.customer.email}</Text>
                            <Text><strong>Phone :</strong> {formatPhoneNumber(booking.customer.phone)}</Text>
                        </Box>
                    </VStack>

                    <VStack mt={25} alignItems={'flex-start'}>
                        <Text fontSize={'xl'} fontWeight={'bold'}>Edit Booking</Text>
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input value={firstName} onChange={handleFirstNameChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Input value={lastName} onChange={handleLastNameChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input value={email} onChange={handleEmailChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone Number</FormLabel>
                            <Input value={phone} onChange={handlePhoneChange}/>
                        </FormControl>
                    </VStack>

                    <VStack mt={50} alignItems={'flex-start'}>
                        <Text fontSize={'xl'} fontWeight={'bold'}>Send Email</Text>
                        <Textarea placeholder={'Message'}/>
                        <Button colorScheme={'green'}>Send</Button>
                    </VStack>

                    <Button w={'100%'} mt={50} colorScheme={'blue'}>Resend Confirmation</Button>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={cancelBooking} colorScheme={'red'}>
                        Cancel Booking
                    </Button>
                    <Button colorScheme='blue' onClick={updateBooking}>Save</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}