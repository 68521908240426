import React from "react";
import {Box, Divider, Heading, HStack, Img, Text, VStack} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import PackageCard from "../../components/PackageCard/PackageCard";

const ServicePage = () => {

    const {infoService} = useSelector(state => state);
    console.log(infoService)
    const placeholderImage = 'https://picsum.photos/500'; // Replace with your desired image size

    return (
        <VStack w={["90%", '70%']} m={'auto'} h={'fit-content'} pt={25} pb={75}>
            <Heading fontWeight={'normal'} fontSize={'6xl'} align={'left'}
                     w={'100%'}>{infoService.name}</Heading>
            <HStack w={'100%'} gap={[10, 0]} flexWrap={'wrap'}>
                <Box w={["100%", '50%']} m={'auto'} mt={0}>
                    <Text fontSize={'3xl'} align={'left'}>{infoService.secondaryName}</Text>
                    <Text fontSize={'lg'} align={'left'} w={['100%', "90%"]}>{infoService.description}</Text>
                </Box>
                <Box w={['100%', '50%']}>
                    <Img src={placeholderImage} alt="Placeholder"/>
                </Box>
            </HStack>
            <Divider mt={25} w={'100%'}/>
            <Text mt={'25'} fontSize={'3xl'}>Schedule Your Appointment Today!</Text>
              <Heading>Pricing Coming Soon!</Heading>
          {/*<HStack w={'80%'} m={'auto'} mt={25} wrap={'wrap'}>*/}
          {/*    {infoService.packages.map((p, index) => {*/}
          {/*        if (index === 1) {*/}
          {/*            return <PackageCard pkg={p} h={'350'}*/}
          {/*                                link={`/scheduling`}*/}
          {/*                                service={infoService}/>*/}
          {/*        }*/}
          {/*        return <PackageCard pkg={p} link={`/scheduling`}*/}
          {/*                            service={infoService}/>*/}
          {/*    })}*/}
          {/*</HStack>*/}
        </VStack>
    )
}

export default ServicePage;
