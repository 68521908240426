import React from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel, HStack,
    Input,
    Stack,
    Text,
    Textarea
} from "@chakra-ui/react";

const Contact = () => {

    return (
        <Box w={'70%'} m={'auto'}>
            <Text fontSize={'4xl'}>Contact Us!</Text>
            <Stack direction={'row'} w={'100%'}>
                <Stack w={'50%'} m={'auto'} spacing={5}>
                    <Text fontSize={'2xl'}>Shoot Us A Message!</Text>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input bgColor={'white'} placeholder={"John"} type={'name'}/>
                        <FormHelperText align={'left'}>Enter your name</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input bgColor={'white'} placeholder={"johndoe@email.com"} type={'email'}/>
                        <FormHelperText align={'left'}>Enter your email</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Message</FormLabel>
                        <Textarea bgColor={'white'} placeholder={'Message'}/>
                    </FormControl>
                    <Button bgColor={'black'} color={'white'}>Send</Button>
                </Stack>

                <Divider orientation={'vertical'} w={25} h={'500px'} m={10} mt={50}/>
                <Stack w={'50%'} mt={50} textAlign={'left'}>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Contact Information</Text>
                    <HStack>
                        <Text fontSize={'xl'} fontWeight={'bold'}>Email:</Text>
                        <Text fontSize={'xl'}>Agentkj1@gmail.com</Text>
                    </HStack>
                    <HStack>
                        <Text fontSize={'xl'} fontWeight={'bold'}>Call or Text:</Text>
                        <Text fontSize={'xl'}>713-874-9745</Text>
                    </HStack>

                    <Box mt={25}>
                        <Text fontSize={'2xl'} fontWeight={'bold'}>Address</Text>
                        <Text fontSize={'xl'}>Salon Lofts: 23541 Westheimer Pkwy, Katy, TX 77494</Text>
                    </Box>

                </Stack>
            </Stack>
        </Box>
    )
}

export default Contact;