import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    FormControl, FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    VStack
} from "@chakra-ui/react";
import axios from "axios";

export default function EditServiceModal({showEditService, closeEditServiceModal, service, getServices}) {

    const [serviceName, setServiceName] = useState(service.name)
    const [serviceBrief, setServiceBrief] = useState(service.brief)
    const [serviceDescription, setServiceDescription] = useState(service.description)

    const handleNameChange = (e) => setServiceName(e.target.value)
    const handleBriefChange = (e) => setServiceBrief(e.target.value)
    const handleDescriptionChange = (e) => setServiceDescription(e.target.value)

    const editService = async () => {
        const data = {
            name: serviceName === service.name ? null : serviceName,
            description: serviceDescription === service.description ? null : serviceDescription,
            brief: serviceBrief === service.brief ? null : serviceBrief
        }

        const response = await axios.patch(`/api/service/editService/${service._id}`, data)
        if(response.status === 200){
            getServices();
            closeEditServiceModal()
        }
    }

    return (
        <Modal isOpen={showEditService} onClose={closeEditServiceModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader fontSize={'2xl'}>Edit Service</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <VStack gap={5}>
                        <FormControl>
                            <FormLabel>Service Name</FormLabel>
                            <Input value={serviceName} onChange={handleNameChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Brief Description</FormLabel>
                            <Textarea value={serviceBrief} onChange={handleBriefChange}/>
                            <FormHelperText>To be used on the homepage for the service card.</FormHelperText>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Brief Description</FormLabel>
                            <Textarea value={serviceDescription} onChange={handleDescriptionChange}/>
                            <FormHelperText>To be used on the service page to describe the service.</FormHelperText>
                        </FormControl>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='green' mr={3} onClick={editService}>
                        Save Service
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}