import React from 'react';
import {Box, Container, HStack, Icon, Link, VStack} from "@chakra-ui/react";
import {BsBank, BsCalendar2Event, BsGearWideConnected, BsHouseDoor, BsPeople, BsPerson, BsTools} from "react-icons/bs";

const dashLinks = [
    // {
    // name: 'Bookings',
    // icon: BsHouseDoor
    // },
    // {
    //     name: "Sales",
    //     icon: BsBank
    // },
    {
        name: "Bookings",
        icon: BsCalendar2Event
    },
    {
        name: "Customers",
        icon: BsPeople
    },
    {
        name: "Services",
        icon: BsTools
    },
    {
        name: "Admins",
        icon: BsPerson
    },
    {
        name: "My Account",
        icon: BsGearWideConnected
    }
]

export default function AdminNav({adminNav, setAdminNav}) {

    return (
        <Box bgColor={'darkgrey'} h={1800} w={300} color={'white'} flexGrow={"1"}>
            <VStack mt={100} justifyContent={'flex-start'} align={'left'} spacing={5}>
                {dashLinks.map(d =>
                    <HStack cursor={'pointer'} onClick={() => setAdminNav(d.name)}
                            bgColor={d.name === adminNav ? 'rgb(231, 231, 231)' : 'none'}
                            color={d.name === adminNav ? 'black' : 'white'} pl={10} pt={2} pb={2}>
                        <Icon as={d.icon} boxSize={6}/>
                        <Link align={'left'} onClick={() => setAdminNav(d.name)}>{d.name}</Link>
                    </HStack>)}
            </VStack>
        </Box>
    )
}