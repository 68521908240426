import React, {useRef} from 'react';
import {
    Button,
    FormControl, FormHelperText,
    FormLabel,
    Input, InputGroup, InputLeftElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea, useToast,
    VStack
} from "@chakra-ui/react";
import axios from "axios";

export default function NewPackageModal({showAddPackage, closeAddPackageModal, service, getServices}) {

    const newPackageName = useRef("")
    const newPackagePrice = useRef("")
    const newPackageDescription = useRef("")

    const toast = useToast();

    async function addPackage() {
        const newPackage = {
            name: newPackageName.current.value,
            price: +newPackagePrice.current.value,
            description: newPackageDescription.current.value
        }
        const createdPackage = await axios.post(`/api/service/addPackage/${service._id}`, newPackage);
        if (createdPackage.status === 200) {
            toast({
                title: 'Package Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            getServices();
            closeAddPackageModal();
            newPackageName.current.value = ""
            newPackagePrice.current.value = ""
            newPackageDescription.current.value = ""
        }
    }

    return (
        <Modal isOpen={showAddPackage} onClose={closeAddPackageModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader fontSize={'2xl'}>Add Package</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <VStack>
                        <Input placeholder={'Package Name'} ref={newPackageName}/>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                color='gray.300'
                                fontSize='1.2em'
                            >
                                $
                            </InputLeftElement>
                            <Input placeholder='0.00' ref={newPackagePrice}/>
                        </InputGroup>
                        <Input placeholder='Package Description' ref={newPackageDescription}/>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='green' mr={3} onClick={addPackage}>
                        Add Package
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}