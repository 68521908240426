import React, {useEffect, useState} from "react";
import {Stack, Text} from "@chakra-ui/react";
import axios from "axios";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_test_51OZlRTGULYki0W1yq4tkqqplpDP39HjHqgMjqec5BIYt8yyPvy48ozk8M06pGkrAs6BzI3IZ95K9XhaPd7QhpeJ800hxXC1lgC');

const PaymentSuccess = () => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    useEffect(() => {
        if (clientSecret) {
            setLoading(false); // Set loading to false immediately, as the message will be updated once the payment intent is retrieved
        }
    }, [clientSecret]);

    return (
        <Elements stripe={stripePromise}>
            <Message clientSecret={clientSecret} loading={loading} setMessage={setMessage} message={message}/>
        </Elements>
    );
};

const Message = ({clientSecret, loading, setMessage, message}) => {
    const stripe = useStripe();

    const queryParams = new URLSearchParams(window.location.search);
    const customer = queryParams.get("customer"); //object id
    const service = queryParams.get("service"); //object id
    const pkg = queryParams.get("package"); //object id
    const time = queryParams.get("time");
    const date = queryParams.get("date");
    const paymentSecret = queryParams.get("payment_intent_client_secret");

    useEffect(() => {
        if (!stripe || !clientSecret) return;

        stripe.retrievePaymentIntent(clientSecret)
            .then(({paymentIntent}) => {
                switch (paymentIntent.status) {
                    case "succeeded":
                        setMessage("Payment succeeded!");
                        createBooking()
                        break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            })
            .catch(error => {
                console.error("Error retrieving payment intent:", error);
                setMessage("An unexpected error occurred.");
            });
    }, [stripe, clientSecret, setMessage]);


    const createBooking = async () => {

        const getPackageResponse = await axios.get(`/api/package/${pkg}`);
        const foundPackage = getPackageResponse.data;
        console.log(foundPackage)
        // const createPurchasedPackageResponse = await axios.post("/api/purchased/", {package: pkg, customer: customer._id, paymentSecret, maxSessions: foundPackage.sessions})
        // const purchased = createPurchasedPackageResponse.data;
        const createBookingResponse = await axios.post(`/api/bookings/`, {
            service,
            customer,
            date,
            time,
            pkg: foundPackage,
            paymentSecret,
        })
        // const booking = createBookingResponse.data
        // const addBookingToPurchasedResponse = await axios.put(`/api/purchased/addBooking/${purchased._id}/${booking._id}`)
        // const addPackageResponse = await axios.put(`/api/customer/addPackage/${customer._id}/${pkg}`)
        console.log(customer)
        // await axios.post("/api/bookings/", {
        //     firstName, lastName, email, phone, service, time, date: date.replaceAll("-", "/")
        // });
    };

    const formatTime = () => {
        const hour = time % 12 || 12; // Convert 0 to 12
        const period = time >= 12 ? "PM" : "AM";
        return `${hour}:00 ${period}`;
    };

    if (loading) {
        return (
            <Stack w={'100vw'} h={'400px'} justifyContent={'center'} h={'60vh'}>
                <Text>Loading...</Text>
            </Stack>
        );
    }

    return (
        <Stack w={'100vw'} h={'400px'} justifyContent={'center'} h={'60vh'}>
            <Text fontSize={'6xl'}>{message}</Text>
            <Text fontSize={'5xl'}>We will see you on {date} at {formatTime()}!</Text>
        </Stack>
    );
}

export default PaymentSuccess;
