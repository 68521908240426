import React, {useState} from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    CloseButton,
    Heading, HStack,
    Input,
    Stack,
    Text
} from "@chakra-ui/react";
import BlackButton from "../../../components/Buttons/BlackButton";
import axios from "axios";

const Subscribe = () => {

    const [email, setEmail] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const handleClick = async () => {
        const response = await axios.post('/api/subscriber/', {email});
        if (response.status === 200) {
            setShowAlert(true);
        }
    }

    return (
      <Box>

        <Stack w={"100vw"} h={250} bgColor={'lightgrey'} spacing={-5} mt={100}>
            <Box mt={2}>
                <Heading fontWeight={'normal'} fontSize={["6xl",'7xl']}>Subscribe</Heading>
                <Text fontSize={'xl'} mt={-5}>to our newsletter and save 15%</Text>
            </Box>
            <HStack wrap={'wrap'} w={['75%','25%']} m={'auto'} justifyContent={'center'}>
                <Input variant={'solid'} placeholder={'Email'} value={email} onChange={e => setEmail(e.target.value)}/>
                <BlackButton w={150} onClick={handleClick}>Subscribe</BlackButton>
            </HStack>
        </Stack>
          {showAlert ? <Alert status='success' w={'25%'} m={'auto'} mt={15}>
              <AlertIcon/>
              <Box w={'85%'}>
                  <AlertTitle>Success!</AlertTitle>
                  <AlertDescription>
                      You are now subscribed!
                  </AlertDescription>
              </Box>
              <CloseButton
                alignSelf='flex-start'
                position='relative'
                right={-1}
                top={-1}
                onClick={() => setShowAlert(false)}
              />
          </Alert> : <></>}
      </Box>
    )
}

export default Subscribe;
