import {
    SET_BOOKING_DATE,
    SET_BOOKING_PACKAGE,
    SET_BOOKING_SERVICE, SET_BOOKING_TIME,
    SET_CONTACT_INFORMATION,
    SET_INFO_SERVICE,
    SET_SERVICES,
    SET_USER,
} from "./actions";

const services = [
    {
        name: "Ultrasonic Cavitation",
        description: "Ultrasonic Cavitation is a non-invasive cosmetic procedure designed to target and reduce localized fat deposits for body contouring. This technique utilizes ultrasound waves, emitted through a handheld device, to penetrate the skin and create pressure changes within the fat cells. As a result, the fat cells experience mechanical vibrations, causing them to break down into smaller particles. The liquefied fat is then naturally metabolized and eliminated from the body through the lymphatic system. Ultrasonic Cavitation is often sought after for its ability to sculpt specific areas, reduce cellulite, and enhance overall body shape without the need for surgery or downtime. While individual results may vary, this procedure offers a promising option for those seeking a non-surgical approach to achieve a more contoured and toned appearance.",
        brief: "Non-invasive fat reduction technique using ultrasound technology",
        packages: [{
            name: "Single Session Package", price: 100
        }, {
            name: "Three Session Package", price: 325
        }, {
            name: "Six Session Package", price: 450
        }]
    },
    {
        name: "EMSlim",
        description: "EMSlim is a game-changing procedure that approaches body contouring from the perspective of both " +
            "fat reduction and muscle toning. That’s important because fat reduction alone can’t create the more well-" +
            "defined appearance that women and men want. For patients who already exercise regularly, EMSCULPT " +
            "treatments can create flatter stomachs, toned arms and calves and tighter, lifted buttocks without surgery." +
            " Because the treatment is non-invasive, there’s no downtime. We recommend scheduling 4 30-minute treatments" +
            " sessions over a 2-week period. After completing a series of 4 treatments, we recommend 2 treatments per " +
            "year for maintenance.",
        brief: "Non-invasive muscle toning method utilizing electromagnetic stimulation technology.",
        packages: [{
            name: "Single Session Package", price: 450
        }, {
            name: "Three Session Package", price: 1425
        }, {
            name: "Six Session Package", price: 2425
        }]
    },
    {
        name: "Laser Lipo",
        description: "Laser Liposuction, commonly known as Laser Lipo, is a minimally invasive cosmetic procedure designed to contour and sculpt the body by targeting localized fat deposits. During the procedure, a thin tube called a cannula is inserted into the targeted area through small incisions. The cannula contains a laser fiber that emits laser energy, which effectively liquefies fat cells. This liquefied fat is then gently suctioned out, resulting in smoother and more refined body contours. Laser Lipo is often chosen for its precision in targeting specific areas, minimal scarring, and shorter recovery times compared to traditional liposuction. The procedure is commonly used to enhance body aesthetics and achieve a more contoured appearance with minimal discomfort for the patient.",
        brief: "Non-surgical fat reduction employing laser technology for contouring.",
        packages: [
            {
                name: "Single Session Package",
                price: 200
            },
            {
                name: "Four Session Package",
                price: 724
            },
            {
                name: "Six Session Package",
                price: 1050
            }
        ]
    },
    {
        name: "Cellulite Reduction",
        description: "Cellulite reduction refers to the various methods and treatments designed to minimize the appearance of cellulite, which is the dimpled or lumpy texture often found on the thighs, buttocks, and other areas of the body. This cosmetic concern is caused by the uneven distribution of fat beneath the skin, leading to a dimpling effect. Cellulite reduction techniques can include non-invasive procedures such as laser therapy, radiofrequency treatments, or mechanical massage devices. These methods aim to target and break down the fibrous bands that pull the skin inward, as well as stimulate collagen production for smoother skin texture. While complete elimination of cellulite may be challenging, these interventions can significantly improve skin tone and reduce the visible signs of cellulite, contributing to a more even and toned appearance.",
        brief: "Treatment targeting dimpled skin for smoother appearance.",
        packages: [
            {
                name: "Single Session Package", price: 150
            },
            {
                name: "Four Session Package", price: 325
            },
            {
                name: "Six Session Package", price: 780
            }]
    },
    {
        name: "Facial Skin Tightening",
        description: "Facial skin tightening is a non-invasive cosmetic procedure designed to enhance the elasticity and firmness of the skin on the face. As individuals age, the skin tends to lose collagen and elasticity, leading to sagging and wrinkles. Facial skin tightening procedures utilize various technologies, such as radiofrequency or ultrasound, to stimulate collagen production and tighten the skin. During the treatment, targeted energy is applied to specific areas of the face, promoting a natural tightening effect over time. This results in a more youthful appearance, with reduced sagging and improved contours. Facial skin tightening is popular for its ability to provide noticeable rejuvenation without the need for surgery, offering individuals a non-surgical option to achieve firmer and smoother skin on the face.",
        brief: "Non-invasive method for firming facial skin appearance.",
        packages: [
            {
                name: "Single Session Package", price: 100
            },
            {
                name: "Four Session Package", price: 325
            },
            {
                name: "Six Session Package", price: 450
            }]
    },
    {
        name: "Butt Lift",
        description: "A butt lift, also known as gluteoplasty, is a cosmetic surgical procedure designed to enhance and reshape the appearance of the buttocks. This procedure aims to lift and firm the buttocks, creating a more youthful and contoured silhouette. There are different techniques for butt lifts, including the use of implants or autologous fat transfer, where fat is harvested from other areas of the body and injected into the buttocks. The choice of technique depends on individual goals and anatomical considerations. Butt lifts have gained popularity for those seeking to achieve a fuller and more aesthetically pleasing buttock contour, providing a tailored solution for enhancing one's overall body proportions. As with any surgical procedure, it is crucial to consult with a qualified plastic surgeon to discuss expectations, potential risks, and ensure that the chosen approach aligns with the individual's desired outcome.",
        brief: "Non-surgical procedure enhancing buttock shape and contour.",
        packages: [
            {
                name: "Single Session Package", price: 250
            },
            {
                name: "Four Session Package", price: 925
            },
            {
                name: "Six Session Package", price: 1350
            }]
    },
    {
        name: "Arm/Thigh Skin Tightening",
        description: "Arm and thigh skin tightening procedures are designed to address sagging or loose skin in these specific areas, often caused by factors such as aging, weight loss, or a decrease in skin elasticity. Various non-invasive and minimally invasive techniques are employed to stimulate collagen production and enhance skin firmness. These procedures may utilize technologies like radiofrequency, ultrasound, or laser energy to target the deeper layers of the skin, promoting collagen remodeling and tightening. As a result, individuals seeking arm and thigh skin tightening treatments can experience a more contoured and toned appearance, restoring a youthful firmness to the skin without the need for surgical intervention. The choice of the specific method depends on individual preferences, desired outcomes, and the recommendation of a qualified skincare professional.",
        brief: "Non-invasive treatment for firmer arm/thigh skin appearance.",
        packages: [
            {
                name: "Single Session Package", price: 150
            },
            {
                name: "Four Session Package", price: 525
            },
            {
                name: "Six Session Package", price: 750
            }]
    },
]

const initialState = {
    user: null,
    services: [],
    infoService: "",
    bookingService: null,
    bookingPackage: null,
    bookingDate: null,
    bookingTime: null,
    contactInformation: null
};

export const reducers = (state = initialState, payload) => {
    switch (payload.type) {
        case SET_USER:
            return {
                ...state, user: payload.user,
            };
        case SET_INFO_SERVICE:
            return {
                ...state, infoService: payload.infoService
            };
        case SET_BOOKING_SERVICE:
            return {
                ...state, bookingService: payload.bookingService
            }
        case SET_BOOKING_PACKAGE:
            return {
                ...state, bookingPackage: payload.bookingPackage
            }
        case SET_SERVICES:
            return {
                ...state, services: payload.services
            }
        case SET_CONTACT_INFORMATION:
            return {
                ...state, contactInformation: payload.contactInformation
            }
        case SET_BOOKING_DATE:
            return {
                ...state, bookingDate: payload.bookingDate
            }
        case SET_BOOKING_TIME:
            return {
                ...state, bookingTime: payload.bookingTime
            }
        default:
            return state;
    }
};

export default reducers;
