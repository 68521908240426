import React from "react";
import {Box, Heading, HStack, Img, Stack, Text, VStack} from "@chakra-ui/react";
import resultsImg from '../../../assets/results.jpeg';

const AmazingResults = () => {
    const placeholderImage = 'https://picsum.photos/500'; // Replace with your desired image size

    return (
        <Box w={"100vw"} h={"fit-content"} bgColor={'#0597B4'} color={'white'} mt={100}>
            <HStack justifyContent="center" alignItems="center" p={[2, 20]}
                   m={'auto'} gap={20} flexWrap="wrap">
                <VStack w={["100%", "40%"]} gap={10} justifyContent={'center'} alignItems={'center'} mt={-10} m={'auto'}>
                    <Heading fontWeight={'normal'} fontSize={['6xl', '7xl']}>Amazing Results</Heading>
                    <Text fontSize={'xl'} align={'left'} w={'100%'}>The EMSlim machine is FDA-approved and is considered safe and effective for most people. The
                        intense muscle contractions created by the EMSlim machine causes the surrounding fat cells to
                        break down and be eliminated by the body's natural processes. The EMSlim machine is a popular
                        treatment option for individuals who want to improve their muscle tone and reduce stubborn
                        pockets of fat.</Text>
                </VStack>
                    <Img src={resultsImg} alt="Placeholder" w={["100%", "50%"]} m={'auto'}/>
            </HStack>
        </Box>
    )
}

export default AmazingResults;