import React, {useEffect, useState} from "react";
import {Box, Button, Card} from "@chakra-ui/react";
import axios from "axios";

const TimeCard = ({time, selectedTime, setSelectedTime, foundBookings}) => {

    const [disabled, setDisabled] = useState(false)

    const handleClick = () => {
        setSelectedTime(time)
    }

    const formatTime = () => {
        const hour = time % 12 || 12; // Convert 0 to 12
        const period = time >= 12 ? "PM" : "AM";
        return `${hour}:00 ${period}`;
    };

    const inBookings = () => {
        let found = false
        foundBookings.forEach(foundBooking => {
            if (+foundBooking.time === time) {
                found = true;
            }
        })
        return found;
    }

    return (
        <Button size={'large'} boxShadow={'2xl'} w={'fit-content'} pl={7} pr={7} pt={3} pb={3}
                bgColor={selectedTime === time ? '' : "black"}
              borderRadius={5} cursor={'pointer'} onClick={handleClick} variant={'solid'} colorScheme={selectedTime === time ? 'green' : 'blackAlpha'} isDisabled={inBookings()}>
            {formatTime()}
        </Button>
    )
}

export default TimeCard;