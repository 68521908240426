import './App.css';
import Landing from "./pages/Landing";
import {Box, ChakraProvider, extendTheme, HStack} from '@chakra-ui/react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Scheduling from "./pages/Scheduling";
import PaymentSuccess from "./pages/PaymentSuccess";
import ServicePage from "./pages/ServicePage";
import Contact from "./pages/Contact";
import React, {useEffect, useState} from "react";
import Navbar from "./components/Navbar";
import '@fontsource/urbanist'
import '@fontsource/dm-serif-display'
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import AdminDashboard from "./pages/AdminDashboard";
import SignIn from "./pages/SignIn";
import API from "./utils/api";
import {useDispatch} from "react-redux";
import {SET_SERVICES} from "./utils/actions";
import AdminNav from "./pages/AdminDashboard/AdminNav";


const customTheme = extendTheme({
    breakpoints: {
        sm: '480px',
        md: '768px',
        lg: '992px',
        xl: '1350px',
        xxl: '1600px'
    },
    fonts: {
        body: 'Urbanist, sans-serif',
        heading: "DM Serif Display, serif",
    }
});

function App() {
    const dispatch = useDispatch()
    const [adminNav, setAdminNav] = useState('Bookings');
    const setServices = async () => {
        const services = await API.getServices();
        dispatch({
            type: SET_SERVICES,
            services
        })
    }

    useEffect(() => {
        setServices();
    }, []);

    return (
        <ChakraProvider theme={customTheme}>
                <Box className="App" position={'relative'} h={'fit-content'} w={"100vw"}>
                    <Router>
                        <Navbar/>
                        <Routes>
                            <Route path="/" exact element={<Landing/>}/>
                            <Route path="/home" exact element={<Landing/>}/>
                            <Route path="/about-us" exact element={<AboutUs/>}/>
                            <Route path="/scheduling" exact element={<Scheduling/>}/>
                            <Route path="/contact" exact element={<Contact/>}/>
                            <Route path="/payment-success" exact element={<PaymentSuccess/>}/>
                            <Route path="/service/*" element={<ServicePage/>}/>
                            <Route path="/admin-login" element={<AdminLogin/>}/>
                            <Route path="/admin-dashboard" element={<AdminDashboard/>}/>
                            <Route path="/sign-in" element={<SignIn/>}/>
                        </Routes>
                        <Footer/>
                    </Router>
                </Box>
        </ChakraProvider>
    );
}

export default App;
