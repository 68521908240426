import React, { useState } from "react";
import {
    Box,
    Divider,
    HStack,
    IconButton,
    Img,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Text,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_INFO_SERVICE } from "../../utils/actions";
import WhiteButton from "../Buttons/WhiteButton";

const Navbar = () => {
    const { services } = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <Box w={"100%"} h={"125px"} bgColor={"rgb(38,38,38, .75)"} position={'sticky'} top={0} justifyContent="center"
             alignItems="center" zIndex={1}>
            <Box bgColor={"black"} w={"100vw"}>
                <Text color={"white"} m={3} mt={0}>
                    Subscribe Now To Save 15%!
                </Text>
            </Box>
            <HStack width={"70%"} m={"auto"} marginBottom={"auto"} color={'white'}>
                <IconButton
                    display={["flex", "none"]}
                    icon={<FaBars />}
                    aria-label="Open Menu"
                    onClick={() => setIsDrawerOpen(true)}
                    color="white"
                    bgColor="transparent"
                    justifyContent="center"
                    alignItems="center"
                />
                <Img src={logo} w={500} m={"auto"} ml={0} onClick={() => navigate("/")} cursor={"pointer"}/>
                <Stack direction={"row"} justifyContent="center" alignItems="center" spacing={10}
                       display={{base: "none", md: "flex"}}>
                    <Link href={"/"} fontWeight={'bold'}>HOME</Link>
                    <Link href={"/about-us"} fontWeight={'bold'}>ABOUT US</Link>
                    <Menu display={{base: "none", md: "block"}}>
                        <MenuButton fontWeight={"bold"}>SERVICES</MenuButton>
                        <MenuList bgColor={"rgb(38,38,38)"}>
                            {services.map((s) => {
                                let link = s.name;
                                if (link.includes(" ")) {
                                    link = link.replaceAll(" ", "-");
                                }
                                if (link.includes("/")){
                                    link = link.replaceAll("/", "-")
                                }
                                return (
                                    <MenuItem
                                        onClick={(e) => {
                                            dispatch({type: SET_INFO_SERVICE, infoService: s});
                                            navigate(`/service/${link}`);
                                        }}
                                        bgColor={"rgb(38,38,38)"}
                                    >
                                        <Link>{s.name}</Link>
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </Menu>
                    <WhiteButton outlined color={'white'} border={'1px solid whiteAlpha.500'}
                                 onClick={() => navigate('/scheduling')}>Book Now</WhiteButton>
                </Stack>
            </HStack>
            <Drawer
                isOpen={isDrawerOpen}
                placement="left"
                onClose={() => setIsDrawerOpen(false)}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Menu</DrawerHeader>
                    <DrawerBody>
                        <Stack spacing={4}>
                            <Link href={"/"} fontWeight={'bold'}>HOME</Link>
                            <Link href={"/about-us"} fontWeight={'bold'}>ABOUT US</Link>
                            <Link fontWeight={'bold'}>SERVICES</Link>
                            {services.map((s) => (
                                <Link ml={3} onClick={() => {
                                    dispatch({type: SET_INFO_SERVICE, infoService: s});
                                    navigate(`/service/${s.name.replace(" ", "-")}`);
                                }}>{s.name}</Link>
                            ))}
                            <WhiteButton outlined color={'white'} border={'1px solid whiteAlpha.500'}
                                         onClick={() => navigate('/scheduling')}>Book Now</WhiteButton>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default Navbar;
