import React, {useState} from 'react'
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack
} from "@chakra-ui/react";
import axios from "axios";


export default function EditPackageModal({showEditPackage, closeEditPackageModal, sPackage, getServices, serviceId}) {

    const [packageName, setPackageName] = useState(sPackage.name)
    const [sessionCount, setSessionCount] = useState(sPackage.sessions)
    const [packagePrice, setPackagePrice] = useState(sPackage.price)
    const [packageDescription, setPackageDescription] = useState(sPackage.description)

    const handleNameChange = (e) => setPackageName(e.target.value)
    const handleSessionCountChange = (e) => setSessionCount(e.target.value)
    const handlePriceChange = (e) => setPackagePrice(e.target.value)
    const handleDescriptionChange = (e) => setPackageDescription(e.target.value)

    const savePackage = async () => {
        const data = {
            name: packageName === sPackage.name ? null : packageName,
            price: packagePrice === sPackage.price ? null : packagePrice,
            description: packageDescription === sPackage.description ? null : packageDescription,
            sessions: sessionCount === sPackage.sessions ? null : sessionCount
        }

        const response = await axios.patch(`/api/service/editPackage/${sPackage._id}`, data);
        if (response.status === 200) {
            console.log('Success')
            getServices();
            closeEditPackageModal();
        }
    }

    const deletePackage = async () => {
        const response = await axios.delete(`/api/service/package/${serviceId}/${sPackage._id}`);
        if (response.status === 200) {
            getServices();
        }
    }

    return (
        <Modal isOpen={showEditPackage} onClose={closeEditPackageModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader fontSize={'2xl'}>Edit Package</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <VStack gap={5}>
                        <FormControl>
                            <FormLabel>Package Name</FormLabel>
                            <Input value={packageName} onChange={handleNameChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Session Count</FormLabel>
                            <Input value={sessionCount} onChange={handleSessionCountChange}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Package Price</FormLabel>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    color='gray.300'
                                    fontSize='1.2em'
                                >
                                    $
                                </InputLeftElement>
                                <Input value={packagePrice} onChange={handlePriceChange}/>
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Package Description</FormLabel>
                            <Input value={packageDescription} onChange={handleDescriptionChange}/>
                        </FormControl>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={deletePackage} colorScheme={'red'} variant={'outline'} mr={5}>Delete
                        Package</Button>
                    <Button colorScheme='green' mr={3} onClick={savePackage}>
                        Save Package
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}