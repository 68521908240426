import React, {useState} from 'react';
import {
    Button, Center,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Spinner, useToast,
    VStack
} from "@chakra-ui/react";
import axios from "axios";


export default function RemoveAdminModal({showRemoveAdmin, closeRemoveAdminModal, getAdmins, admin}) {

    const toast = useToast();

    const removeAdmin = async () => {
        const response = await axios.delete(`/api/admins/${admin._id}`);
        if(response.status === 200){
            toast({
                title: 'Admin Deleted',
                description: `Successfully removed ${admin.firstName} ${admin.lastName}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            getAdmins()
            closeRemoveAdminModal()
        }
    }


    return (
        <Modal isOpen={showRemoveAdmin} onClose={closeRemoveAdminModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader fontSize={'2xl'}>Remove {admin ? <>{admin.firstName} {admin.lastName}</> : ""}</ModalHeader>
                <ModalCloseButton/>
                <ModalFooter>
                    <Button w={'fit-content'} colorScheme='red' m={'auto'} fontWeight={'bold'} onClick={removeAdmin}>
                        Remove Admin
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}