import React from 'react';
import {Box, FormControl, FormLabel, Heading, Input, Textarea, VStack} from "@chakra-ui/react";
import WhiteButton from "../../../components/Buttons/WhiteButton";

export default function ContactUs(){

    return (
        <Box bgColor={'teal.400'} color={'white'} mt={100} p={10} mb={100}>
            <VStack gap={5} w={['100%', '30%']} m={'auto'}>
                <Heading fontWeight={'normal'} fontSize={['6xl', '7xl']}>Contact Us</Heading>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input color={'black'} w={'100%'} bgColor={'white'} type={'email'} placeholder={'Email'}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input color={'black'} w={'100%'} bgColor={'white'} type={'name'} placeholder={'Name'}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Message</FormLabel>
                    <Textarea color={'black'} bgColor={'white'} w={'100%'} placeholder={'Message'}/>
                </FormControl>
                <WhiteButton border={"1px solid whiteAlpha.800"}>Send Message</WhiteButton>
            </VStack>
        </Box>

    )
}